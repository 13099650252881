import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Outlet,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from "react-router-dom";
import { PlusSmIcon } from "@heroicons/react/outline";
import { differenceInDays, isValid } from "date-fns";
import { ConversationContent } from "@hilos/types/private-schema";
import Loading from "src/components/Loading";
import { useUpdateConversationContent } from "src/hooks/useConversationContent";
import useInboxContactDetails from "src/hooks/useInboxContactDetails";
import useMediaDropzone from "src/hooks/useMediaDropzone";
import NewConversationModal from "../NewConversationModal";
import Conversation from "./Conversation";
import ConversationHeader from "./ConversationHeader";
import ConversationContentSearch from "./ConversationTab/ConversationContentSearch";

function InboxConversation() {
  const isFocusedWithMessage = useMatch(
    "/inbox/conversation/:inboxContactId/message/:messageId"
  );
  const isFocused =
    useMatch("/inbox/conversation/:inboxContactId") || isFocusedWithMessage;
  const { inboxContactId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [showNewConversation, setShowNewConversation] = useState(false);

  const [showConversationSearch, setShowConversationSearch] = useState(false);
  const [focusedConversationContent, setFocusedConversationContent] =
    useState<ConversationContent | null>(null);
  const [disableMessageMediaUpload, setDisableMessageMediaUpload] =
    useState(false);

  const {
    inboxContact,
    isLoading,
    isSubmittingStatus,
    setIsLastMessageFocused,
    handleUpdateInboxContact,
    handleUpdateConversationStatus,
    handleMarkAsRead,
  } = useInboxContactDetails({ inboxContactId });

  const { isSubmitting, handleSendMessage } = useUpdateConversationContent({
    inboxContactId,
  });

  const mustSendTemplate = useMemo(() => {
    if (inboxContact && inboxContact.last_inbound_message_on) {
      const lastInboundMessageTimestamp = new Date(
        inboxContact.last_inbound_message_on
      );

      return (
        !isValid(lastInboundMessageTimestamp) ||
        differenceInDays(new Date(), lastInboundMessageTimestamp) >= 1
      );
    }
    return true;
  }, [inboxContact]);

  const {
    files,
    uploading,
    getRootProps,
    getInputProps,
    isDragActive,
    onPaste,
    onFilePicker,
    onMediaSubmit,
    onCancelUpload,
  } = useMediaDropzone({
    disabled: mustSendTemplate || disableMessageMediaUpload,
    handleSendMessage,
  });

  const handleCloseNewConversation = useCallback(() => {
    setShowNewConversation(false);
  }, []);

  const handleShowNewConversation = useCallback(() => {
    setShowNewConversation(true);
  }, []);

  const handleCloseConversationSearch = useCallback(() => {
    setShowConversationSearch(false);
    setFocusedConversationContent(null);
  }, []);

  const handleDisableMessageMediaUpload = useCallback(
    (nextDisableMessageMediaUpload: boolean) => {
      setDisableMessageMediaUpload(nextDisableMessageMediaUpload);
    },
    []
  );

  useEffect(() => {
    if (inboxContactId) {
      handleMarkAsRead();
      setShowConversationSearch(false);
      setFocusedConversationContent(null);
    }
  }, [inboxContactId]);

  useEffect(() => {
    if (
      inboxContact &&
      inboxContact.merged_inbox_contact &&
      !location.pathname.includes(inboxContact.merged_inbox_contact)
    ) {
      const mergedInboxContactPath = location.pathname.replace(
        inboxContact.id,
        inboxContact.merged_inbox_contact
      );
      navigate(mergedInboxContactPath);
    }
  }, [navigate, location, inboxContact]);

  if (isLoading) {
    return <Loading />;
  }

  if (!inboxContact || !inboxContactId) {
    // ?: isError from useInboxContactDetails maybe has more details
    return (
      <div className="align-center flex h-full w-full flex-col justify-center">
        <div className="text-center align-middle">
          <h4 className="">Looks like this conversation does not exist.</h4>
          <p className="mb-4 text-sm text-gray-500">
            Why don't you create one?
          </p>
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={handleShowNewConversation}
          >
            <PlusSmIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true" />
            New conversation
          </button>
        </div>
        <NewConversationModal
          show={showNewConversation}
          onClose={handleCloseNewConversation}
        />
      </div>
    );
  }

  return (
    <>
      <section
        onPaste={onPaste}
        className={`h-full flex-col border-gray-200 lg:border-r ${
          isFocused && !showConversationSearch
            ? "flex-1 shrink-0"
            : "hidden w-full lg:block"
        }`}
        data-tour="convo"
      >
        <div
          {...getRootProps({
            className: "flex-1 flex flex-col h-full cursor-default",
          })}
        >
          <>
            <ConversationHeader
              inboxContact={inboxContact}
              showConversationSearch={showConversationSearch}
              handleUpdateInboxContact={handleUpdateInboxContact}
              // @ts-ignore
              onUpdateConversationStatus={handleUpdateConversationStatus}
              isSubmittingStatus={isSubmittingStatus}
              setShowConversation={setShowConversationSearch}
            />
            <Conversation
              uploading={uploading}
              inboxContact={inboxContact}
              focusedConversationContent={focusedConversationContent}
              files={files}
              isDragActive={isDragActive}
              isSubmitting={isSubmitting}
              mustSendTemplate={mustSendTemplate}
              onFilePicker={onFilePicker}
              onMediaSubmit={onMediaSubmit}
              onCancelUpload={onCancelUpload}
              setShowConversationSearch={setShowConversationSearch}
              getInputProps={getInputProps}
              onSendMessage={handleSendMessage}
              onUpdateInboxContact={handleUpdateInboxContact}
              onFocusStartOfContent={setIsLastMessageFocused}
              onFocusConversationContent={setFocusedConversationContent}
              onDisableMessageMediaUpload={handleDisableMessageMediaUpload}
            />
          </>
        </div>
      </section>
      {showConversationSearch ? (
        <ConversationContentSearch
          inboxContactId={inboxContactId}
          focusedConversationContent={focusedConversationContent}
          setFocusedConversationContent={setFocusedConversationContent}
          onClose={handleCloseConversationSearch}
        />
      ) : (
        <Outlet />
      )}
    </>
  );
}

function InboxConversationWithKey() {
  const { inboxContactId } = useParams();
  return <InboxConversation key={inboxContactId} />;
}

export default InboxConversationWithKey;
