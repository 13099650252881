import EmojiPicker, { EmojiClickData } from "emoji-picker-react";

interface EmojiPickerProps {
  open: boolean;
  onSelectEmoji: (emoji: EmojiClickData) => void;
}

function BottomBarEmojiPicker({ onSelectEmoji, open }: EmojiPickerProps) {
  return (
    <div className="w-full mb-2">
      <div className="rounded-md border">
        <EmojiPicker
          searchDisabled
          open={open}
          className="!bg-none"
          onEmojiClick={onSelectEmoji}
          lazyLoadEmojis
          skinTonesDisabled
          previewConfig={{ showPreview: false }}
          width="100%"
          height={250}
        />
      </div>
    </div>
  );
}

export default BottomBarEmojiPicker;
