import { useEffect, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { usePostHog } from "posthog-js/react";
import { SessionData } from "@hilos/types/hilos";
import {
  getInboxContactCountQuery,
  getInboxContactCountSubscription,
} from "src/helpers/queries";
import { isFeatureEnabled } from "src/helpers/utils";

interface UseInboxContactCountsParams {
  session: SessionData | null;
  filters: Record<string, any> | null;
  allowSubscriptions?: boolean;
  isSelected?: boolean;
  allowQueryCountWithLimit: boolean;
}

export function useInboxContactCounts({
  session,
  filters,
  isSelected = false,
  allowSubscriptions = false,
  allowQueryCountWithLimit,
}: UseInboxContactCountsParams) {
  const posthog = usePostHog();

  const allowQueryCountSubscription = useMemo(
    () =>
      isFeatureEnabled({
        key: "inbox_contact_count_subscription",
        session,
        posthog,
      }),
    [session, posthog]
  );

  const { data, loading, refetch, subscribeToMore } = useQuery(
    getInboxContactCountQuery(allowQueryCountWithLimit),
    {
      variables: { filters },
      skip: !filters,
      fetchPolicy: isSelected ? "network-only" : "no-cache",
      notifyOnNetworkStatusChange: true,
    }
  );

  const count = useMemo(
    () => data?.api_inboxcontact_aggregate?.aggregate?.count || 0,
    [data]
  );

  useEffect(() => {
    if (
      loading ||
      !filters ||
      !allowSubscriptions ||
      !allowQueryCountSubscription
    ) {
      return;
    }

    const subscription = subscribeToMore({
      document: getInboxContactCountSubscription(allowQueryCountWithLimit),
      variables: { filters },
      updateQuery: (prev, { subscriptionData }) => subscriptionData.data,
    });

    return () => {
      subscription();
    };
  }, [
    loading,
    allowSubscriptions,
    allowQueryCountWithLimit,
    allowQueryCountSubscription,
    filters,
    subscribeToMore,
  ]);

  return {
    count,
    loading,
    refetch,
    subscribeToMore,
  };
}
