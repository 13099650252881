import { useCallback, useRef, useState } from "react";
import { DropzoneInputProps } from "react-dropzone";
import { QueryObserverResult } from "react-query";
import { XIcon } from "@heroicons/react/outline";
import { captureException } from "@sentry/react";
import {
  ConversationContent,
  InboxContactRead,
} from "@hilos/types/private-schema";
import MessageRenderPreview from "src/components/MessageRenderPreview";
import { SendMessageFn } from "src/hooks/useConversationContent";
import { UpdateInboxContactFn } from "src/hooks/useInboxContactDetails";
import BottomBarMessageAudio from "./BottomBarMessageAudio";
import BottomBarMessageInput, {
  BottomBarMessageInputRef,
  MessageMode,
} from "./BottomBarMessageInput";
import ConversationTemplateSendModal from "./ConversationTemplateSendModal";
import QuickRepliesModal from "./QuickRepliesModal";

interface BottomBarMessageFormProps {
  inboxContact: InboxContactRead;
  context: ConversationContent | null;
  getInputProps: <T extends DropzoneInputProps>(props?: T) => T;
  onSendMessage: SendMessageFn;
  onScheduleMessage: () => Promise<QueryObserverResult<any, unknown>>;
  onUpdateInboxContact: UpdateInboxContactFn;
  onSelectContextContent: (content: ConversationContent | null) => void;
  onFocusConversationContent: (data: ConversationContent) => void;
  onDisableMessageMediaUpload: (disable: boolean) => void;
}

function BottomBarMessageForm({
  inboxContact,
  context,
  getInputProps,
  onSendMessage,
  onScheduleMessage,
  onUpdateInboxContact,
  onSelectContextContent,
  onFocusConversationContent,
  onDisableMessageMediaUpload,
}: BottomBarMessageFormProps) {
  const [currentMessageMode, setCurrentMessageMode] =
    useState<MessageMode>(null);
  const messageInputRef = useRef<BottomBarMessageInputRef>(null);

  const handleSendCurrentMessage = useCallback(
    async (message: string) => {
      try {
        if (context && context.message) {
          onSendMessage({
            body: message,
            msg_type: "text",
            context_message: context.message.id,
          });
        } else {
          onSendMessage({
            body: message,
            msg_type: "text",
          });
        }
      } catch (e) {
        captureException(e);
      }
    },
    [context, onSendMessage]
  );

  const handleSetMessage = useCallback(
    (message: string) => {
      if (messageInputRef.current) {
        messageInputRef.current.setMessage(message);
      }
    },
    [messageInputRef]
  );

  const handleCloseMessageMode = useCallback(() => {
    setCurrentMessageMode(null);

    setTimeout(() => {
      if (messageInputRef.current) {
        messageInputRef.current.focus();
      }
    }, 350);
  }, [messageInputRef]);

  return (
    <>
      <div className="min-w-0">
        {context && (
          <div className="flex mb-4 mt-2">
            <button
              className="bg-gray-50 border-l-4 border-indigo-500 self-center  w-full rounded-md p-2 text-sm"
              onClick={() => onFocusConversationContent(context)}
            >
              {context.message && (
                <MessageRenderPreview message={context.message} />
              )}
            </button>
            <button
              onClick={() => onSelectContextContent(null)}
              className="ml-2 rounded-full self-center bg-gray-200 p-2 w-9 h-9 flex justify-center flex-shrink-0"
            >
              <XIcon className="w-4 h-4 text-gray-400 self-center" />
            </button>
          </div>
        )}
        {currentMessageMode === "VOICE_RECORDING" ? (
          <BottomBarMessageAudio
            handleSendMessage={onSendMessage}
            onClose={() => setCurrentMessageMode(null)}
          />
        ) : (
          <BottomBarMessageInput
            ref={messageInputRef}
            inboxContact={inboxContact}
            currentMessageMode={currentMessageMode}
            getInputProps={getInputProps}
            setCurrentMessageMode={setCurrentMessageMode}
            onScheduleMessage={onScheduleMessage}
            onSendCurrentMessage={handleSendCurrentMessage}
            onUpdateInboxContact={onUpdateInboxContact}
          />
        )}
      </div>
      <QuickRepliesModal
        inboxContact={inboxContact}
        show={currentMessageMode === "QUICK_REPLIES"}
        onClose={handleCloseMessageMode}
        onSetMessage={handleSetMessage}
      />
      <ConversationTemplateSendModal
        inboxContact={inboxContact}
        open={currentMessageMode === "SELECT_TEMPLATE"}
        onClose={handleCloseMessageMode}
        onDisableMessageMediaUpload={onDisableMessageMediaUpload}
      />
    </>
  );
}

export default BottomBarMessageForm;
