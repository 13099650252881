import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ArrowSmLeftIcon,
  ArrowSmRightIcon,
  ChatIcon,
  ClipboardListIcon,
  PlusIcon,
} from "@heroicons/react/outline";
import { Formik } from "formik";
import * as yup from "yup";
import { WhatsAppTemplate } from "@hilos/types/wa/templates";
import * as templatesMeta from "src/containers/wa/WhatsAppTemplateMeta";
import { getFormValuesFromQR } from "src/helpers/quickReply";
import FieldContainer from "../../../components/Form/FieldContainer";
import SelectorField from "../../../components/Form/SelectorField";
import TextAreaVariablesField from "../../../components/Form/TextAreaVariablesField";
import TextInputField from "../../../components/Form/TextInputField";
import { ERROR_MESSAGES } from "../../../constants/Form";
import {
  QuickReplyData,
  QuickReplyPayload,
  QuickReplyTagData,
} from "../../../hooks/useQuickReplies";
import QuickReplyTemplateModal from "./QuickReplyTemplateModal";

interface QuickRepliesFormProps {
  tags?: QuickReplyTagData[];
  onCreateQuickReply: (params: QuickReplyPayload) => Promise<void>;
  onUpdateQuickReply: (params: QuickReplyPayload, id: string) => Promise<void>;
  quickReply?: QuickReplyData;
  onCancelEdit: () => void;
  setSelectedTemplate: (t: WhatsAppTemplate | undefined) => void;
  selectedTemplate?: WhatsAppTemplate;
  setOpen: (t: boolean) => void;
  open: boolean;
  setShowForm: (t: boolean) => void;
  showForm: boolean;
  setIsUpdate: (t: boolean) => void;
  isUpdate: boolean;
}

const initialValues = {
  text: "",
  tags: [],
  whatsapp_template: "",
  name: "",
};

function QuickRepliesForm({
  tags,
  onCreateQuickReply,
  onUpdateQuickReply,
  quickReply,
  onCancelEdit,
  setSelectedTemplate,
  selectedTemplate,
  setOpen,
  open,
  setShowForm,
  showForm,
  setIsUpdate,
  isUpdate,
}: QuickRepliesFormProps) {
  const [selectedTypeQuickReply, setSelectedTypeQuickReply] = useState("");
  const { t } = useTranslation();

  const schema =
    selectedTypeQuickReply === "text"
      ? yup.object().shape({
          text: yup.string().required(ERROR_MESSAGES.required),
          name: yup.string().max(100, ERROR_MESSAGES.max),
        })
      : yup.object().shape({
          template: yup.string().required(ERROR_MESSAGES.required),
          name: yup.string().max(100, ERROR_MESSAGES.max),
        });

  useEffect(() => {
    if (quickReply) {
      if (quickReply.text) {
        setShowForm(true);
        setSelectedTypeQuickReply("text");
      } else if (quickReply.template && open) {
        setShowForm(true);
        setSelectedTypeQuickReply("template");
      }
    } else {
    }
  }, [quickReply, open, setShowForm]);

  const handleSubmit = useCallback(
    async (values) => {
      const nextTags = values.tags && values.tags.map((name) => ({ name }));
      if (selectedTypeQuickReply === "template") {
        delete values["text"];
      }
      if (selectedTypeQuickReply === "text") {
        delete values["template"];
        delete values["template_variables"];
      }

      setSelectedTemplate(undefined);
      setSelectedTypeQuickReply("");

      if (quickReply) {
        await onUpdateQuickReply(
          {
            ...values,
            tags: nextTags,
          },
          quickReply.id
        );
        setShowForm(false);
        setSelectedTypeQuickReply("");
      } else {
        await onCreateQuickReply({
          ...values,
          tags: nextTags,
        });
      }
    },
    [
      onCreateQuickReply,
      onUpdateQuickReply,
      quickReply,
      selectedTypeQuickReply,
      setSelectedTemplate,
      setShowForm,
    ]
  );

  const onCancel = () => {
    setSelectedTypeQuickReply("");
    setIsUpdate(false);
    setSelectedTemplate(undefined);
    setOpen(false);
    setShowForm(false);
    onCancelEdit();
  };

  if (!showForm) {
    return (
      <div className="mx-2 my-4">
        <button
          type="button"
          className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          onClick={() => {
            setShowForm(true);
          }}
        >
          <div className="mx-2 inline-flex items-center">
            {t("inbox:quick-replies.add-quick-reply", "Add quick reply")}
            <PlusIcon className="ml-1 -mr-1 h-5 w-5" aria-hidden="true" />
          </div>
        </button>
      </div>
    );
  }

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      initialValues={
        quickReply ? getFormValuesFromQR(quickReply) : initialValues
      }
    >
      {(formik) => (
        <form
          noValidate
          onSubmit={formik.handleSubmit}
          className="border-t border-gray-200 flex h-full flex-col justify-start py-4 px-2"
        >
          {!quickReply && (
            <>
              {!selectedTypeQuickReply ? (
                <>
                  <p className="mb-2 text-sm font-medium text-gray-700">
                    {t(
                      "inbox:quick-replies.select-type",
                      "Select type of Quick Reply"
                    )}
                  </p>
                  <div className="flex gap-2">
                    <button
                      type="button"
                      className="flex w-full items-center justify-center rounded-md border border-gray-400 bg-transparent px-4 py-2 text-sm font-medium text-gray-600 "
                      onClick={() => {
                        setSelectedTypeQuickReply("template");
                        setOpen(true);
                      }}
                    >
                      <ClipboardListIcon
                        className="mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      {t("template", "Template")}
                    </button>
                    <button
                      type="button"
                      className="flex w-full items-center justify-center rounded-md border border-gray-400 bg-transparent px-4 py-2 text-sm font-medium text-gray-600 "
                      onClick={() => {
                        setSelectedTypeQuickReply("text");
                      }}
                    >
                      <ChatIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                      {t("text", "Text")}
                    </button>
                  </div>
                </>
              ) : (
                <button
                  type="button"
                  className="mb-2 max-w-fit flex items-center justify-center rounded-md border border-gray-400 bg-transparent px-2 py-1 text-xs font-medium text-gray-500 "
                  onClick={() => {
                    setSelectedTypeQuickReply("");
                  }}
                >
                  <ArrowSmLeftIcon
                    className="mr-2 h-4 w-4"
                    aria-hidden="true"
                  />
                  {t("back", "Back")}
                </button>
              )}
            </>
          )}
          <QuickReplyTemplateModal
            onClose={() => setOpen(false)}
            onCancel={onCancel}
            open={open}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            formik={formik}
            isUpdate={isUpdate}
          />
          {selectedTypeQuickReply === "text" && (
            <div>
              <FieldContainer
                name="name"
                label={t("inbox:quick-replies.name.label", "Name")}
                optional
              >
                <div className="mb-4">
                  <TextInputField
                    type="text"
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    className="input-class"
                    placeholder={t(
                      "inbox:quick-replies.name.placeholder",
                      "Enter a name"
                    )}
                  />
                  {formik.touched.name &&
                  typeof formik.errors.name === "string" ? (
                    <div className="error">{formik.errors.name}</div>
                  ) : null}
                </div>
              </FieldContainer>
              <TextAreaVariablesField
                name="text"
                label={t(
                  "inbox:quick-replies.text-quick-reply.label",
                  "Add a text quick reply"
                )}
                placeholder={t(
                  "inbox:quick-replies.text-quick-reply.placeholder",
                  "Thanks for reaching out..."
                )}
              />
              <FieldContainer
                name="tags"
                label={t("inbox:quick-replies.tags.label", "Tags")}
                optional
              >
                <SelectorField
                  isMulti
                  isCreatable
                  name="tags"
                  options={
                    tags &&
                    tags.map((tag) => ({
                      label: tag.name,
                      value: tag.name,
                    }))
                  }
                />
              </FieldContainer>
            </div>
          )}
          {selectedTypeQuickReply === "template" && selectedTemplate && (
            <div>
              <FieldContainer
                name="name"
                label={t("inbox:quick-replies.name.label", "Name")}
                optional
              >
                <div className="mb-4">
                  <TextInputField
                    type="text"
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    className="input-class"
                    placeholder={t(
                      "inbox:quick-replies.name.placeholder",
                      "Enter a name"
                    )}
                  />
                  {formik.touched.name &&
                  typeof formik.errors.name === "string" ? (
                    <div className="error">{formik.errors.name}</div>
                  ) : null}
                </div>
              </FieldContainer>
              <button
                type="button"
                className="mb-4 flex w-full items-center justify-center rounded-md border border-gray-400 bg-transparent px-4 py-2 text-sm font-medium text-gray-600 "
                onClick={() => {
                  setOpen(true);
                }}
              >
                <div className="w-full py-2">
                  <div className="flex items-center justify-between">
                    <div>
                      <h4 className="break-all font-medium">
                        {selectedTemplate?.name}
                      </h4>
                    </div>
                    {selectedTemplate &&
                      selectedTemplate.category &&
                      templatesMeta.CATEGORIES[selectedTemplate.category] && (
                        <div className="pl-4">
                          {
                            templatesMeta.CATEGORIES[selectedTemplate.category]
                              .icon
                          }
                        </div>
                      )}
                  </div>
                  <div className="truncate text-left text-xs">
                    {templatesMeta.getTemplateShortPreview(selectedTemplate)}
                  </div>
                </div>
              </button>
              <FieldContainer
                name="tags"
                label={t("inbox:quick-replies.tags.label", "Tags")}
                optional
              >
                <SelectorField
                  isMulti
                  isCreatable
                  name="tags"
                  options={
                    tags &&
                    tags.map((tag) => ({
                      label: tag.name,
                      value: tag.name,
                    }))
                  }
                />
              </FieldContainer>
            </div>
          )}
          <div className="flex">
            {quickReply || selectedTypeQuickReply ? (
              <>
                <button
                  type="button"
                  onClick={onCancel}
                  disabled={formik.isSubmitting}
                  className="borde-indigo-600 mt-4 mr-2 flex w-full items-center justify-center rounded-md border py-2 px-4 text-sm font-medium text-indigo-600 shadow-sm hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  {t("cancel", "Cancel")}
                </button>

                <button
                  type="submit"
                  disabled={formik.isSubmitting}
                  className="mt-4 flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  {formik.isSubmitting ? (
                    <>
                      <svg
                        className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      {t("saving", "Saving...")}
                    </>
                  ) : (
                    <div className="inline-flex items-center">
                      {t(
                        "inbox:quick-replies.save-quick-reply",
                        "Save quick reply"
                      )}
                      <ArrowSmRightIcon
                        className="ml-1 -mr-1 h-5 w-5"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </button>
              </>
            ) : (
              <button
                type="button"
                onClick={onCancel}
                disabled={formik.isSubmitting}
                className="borde-indigo-600 mt-4 mr-2 flex w-full items-center justify-center rounded-md border py-2 px-4 text-sm font-medium text-indigo-600 shadow-sm hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                {t("cancel", "Cancel")}
              </button>
            )}
          </div>
        </form>
      )}
    </Formik>
  );
}

export default QuickRepliesForm;
