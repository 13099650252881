import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusSmIcon, UserGroupIcon, XIcon } from "@heroicons/react/outline";
import { ConversationReadSimple } from "@hilos/types/private-schema";
import { userAvatar, userToString } from "../../../../Helpers";
import { UpdateConversationFn } from "../../../../hooks/useInboxContactDetails";
import AssignmentForm from "./AssignmentForm";

interface AssignmentListProps {
  conversation: ConversationReadSimple;
  onUpdateConversation: UpdateConversationFn;
}

function AssignmentList({
  conversation,
  onUpdateConversation,
}: AssignmentListProps) {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);

  const handleRemoveUser = useCallback(
    (userIdToRemove) => {
      return () => {
        const nextAssignment = [...conversation.assigned]
          .filter((user) => user.id !== userIdToRemove)
          .map((user) => ({ id: user.id }));
        onUpdateConversation({ assigned: nextAssignment });
        setShowForm(false);
      };
    },
    [onUpdateConversation, conversation]
  );

  return (
    <div data-tour="assigned-to">
      <div className="flex items-center px-3 overflow-x-hidden">
        <div className="flex-1 text-sm font-semibold uppercase tracking-wide text-gray-500">
          {t("inbox:assigned-to", "Assigned to")}
        </div>
        <div className="flex-shrink-0">
          {showForm ? (
            <button
              className="inline-flex h-8 w-8 items-center justify-center rounded-full text-gray-500 transition duration-150 ease-in-out hover:bg-gray-300 focus:outline-none"
              type="button"
              onClick={(_) => setShowForm(false)}
            >
              <XIcon className="h-5 w-5" />
            </button>
          ) : (
            <button
              className="inline-flex h-8 w-8 items-center justify-center rounded-full text-gray-500 transition duration-150 ease-in-out hover:bg-gray-300 focus:outline-none"
              type="button"
              onClick={(_) => setShowForm(true)}
            >
              <PlusSmIcon className="h-5 w-5" />
            </button>
          )}
        </div>
      </div>

      {showForm ? (
        <AssignmentForm
          conversation={conversation}
          onClose={() => setShowForm(false)}
          onUpdateConversation={onUpdateConversation}
        />
      ) : (
        <div className="overflow-y-auto bg-gray-100 overflow-x-hidden">
          <ul className="divide-y divide-gray-200 overflow-x-hidden">
            {conversation.assigned.length === 0 ? (
              <p className="px-3 text-xs text-gray-400">
                No one is assigned yet.
              </p>
            ) : (
              <>
                {conversation.assigned.map((user, idx) => (
                  <li
                    key={idx}
                    className="py-1 px-3 hover:bg-gray-200 overflow-x-hidden flex items-center space-x-2"
                  >
                    <div className="flex-shrink-0">
                      {userAvatar(
                        user,
                        `h-6 w-6 text-xs ring-2 ring-gray-100 group-hover:ring-gray-200`
                      )}
                    </div>
                    <div className="flex flex-1 min-w-0 space-x-2 items-center justify-between">
                      <span className="truncate text-sm font-medium text-gray-900 overflow-hidden">
                        {userToString(user)}
                      </span>
                      {/* TODO: Should this serializer be updated to send this information? */}
                      {/* {user.teams && user.teams.length > 0 && (
                        <div className="inline-flex items-center max-w-[12rem] truncate rounded bg-indigo-100 px-2 py-0.5 text-xs font-medium text-indigo-800">
                          <UserGroupIcon className="mr-1 h-4 w-4 shrink-0" />
                          <span className="truncate">{user.teams[0].name}</span>
                        </div>
                      )} */}
                    </div>
                    <div>
                      <button
                        type="button"
                        className="inline-flex h-8 w-8 items-center justify-center rounded-full text-gray-400 transition duration-150 ease-in-out focus:outline-none"
                        onClick={handleRemoveUser(user.id)}
                      >
                        <XIcon className="h-4 w-4 text-gray-500" />
                      </button>
                    </div>
                  </li>
                ))}
              </>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}

export default AssignmentList;
