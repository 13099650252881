import {
  CalendarCheck2Icon,
  CodeIcon,
  EyeIcon,
  ReceiptIcon,
  ShoppingCartIcon,
} from "lucide-react";
import { getTranslationPayload as t } from "src/i18n";

// From all the events Meta's API says it allows, only the uncommented ones
// work with the business_messaging action_source param (obviously it's not
// documented at all on Meta's API docs)
export const CAPI_CONVERSION_EVENTS = {
  // AddPaymentInfo: {
  //   value: "ADD_PAYMENT_INFO",
  //   icon: CreditCardIcon,
  //   label: "AddPaymentInfo",
  //   help: t(
  //     "flows:capi-conversion-events.AddPaymentInfo.help",
  //     "When payment information is added in the checkout flow (e.g. A person clicks on a save billing information button)"
  //   ),
  // },
  AddToCart: {
    value: "ADD_TO_CART",
    icon: ShoppingCartIcon,
    accept: "image/png,image/jpg,image/jpeg",
    label: "AddToCart",
    help: t(
      "flows:capi-conversion-events.AddToCart.help",
      "When a product is added to the shopping cart (e.g. A person clicks on an add to cart button."
    ),
  },
  // AddToWishlist: {
  //   value: "ADD_TO_WISHLIST",
  //   icon: ListCheckIcon,
  //   accept: "application/pdf",
  //   label: "AddToWishlist",
  //   help: t(
  //     "flows:capi-conversion-events.AddToWishlist.help",
  //     "When a product is added to a wishlist."
  //   ),
  // },
  // CompleteRegistration: {
  //   value: "COMPLETE_REGISTRATION",
  //   icon: UserCheckIcon,
  //   accept: "video/mp4",
  //   label: "CompleteRegistration",
  //   help: t(
  //     "flows:capi-conversion-events.CompleteRegistration.help",
  //     "When a registration form is completed."
  //   ),
  // },
  // Contact: {
  //   value: "CONTACT",
  //   icon: PhoneIncomingIcon,
  //   accept: "video/mp4",
  //   label: "Contact",
  //   help: t(
  //     "flows:capi-conversion-events.Contact.help",
  //     "When a person initiates contact with your business via telephone, SMS, email, chat, etc."
  //   ),
  // },
  // CustomizeProduct: {
  //   value: "CUSTOMIZE_PRODUCT",
  //   icon: Settings2Icon,
  //   accept: "video/mp4",
  //   label: "CustomizeProduct",
  //   help: t(
  //     "flows:capi-conversion-events.CustomizeProduct.help",
  //     "When a person customizes a product."
  //   ),
  // },
  // Donate: {
  //   value: "DONATE",
  //   icon: HandCoinsIcon,
  //   accept: "video/mp4",
  //   label: "Donate",
  //   help: t(
  //     "flows:capi-conversion-events.Donate.help",
  //     "When a person donates funds to your organization or cause."
  //   ),
  // },
  // FindLocation: {
  //   value: "FIND_LOCATION",
  //   icon: MapPinIcon,
  //   accept: "video/mp4",
  //   label: "FindLocation",
  //   help: t(
  //     "flows:capi-conversion-events.FindLocation.help",
  //     "When a person searches for a location of your store via a website or app, with an intention to visit the physical location."
  //   ),
  // },
  InitiateCheckout: {
    value: "INITIATE_CHECKOUT",
    icon: ReceiptIcon,
    accept: "video/mp4",
    label: "InitiateCheckout",
    help: t(
      "flows:capi-conversion-events.InitiateCheckout.help",
      "When a person enters the checkout flow prior to completing the checkout flow."
    ),
  },
  // Lead: {
  //   value: "LEAD",
  //   icon: UserPlusIcon,
  //   accept: "video/mp4",
  //   label: "Lead",
  //   help: t(
  //     "flows:capi-conversion-events.Lead.help",
  //     "When a sign up is completed."
  //   ),
  // },
  Purchase: {
    value: "PURCHASE",
    icon: ReceiptIcon,
    accept: "video/mp4",
    label: "Purchase",
    help: t(
      "flows:capi-conversion-events.Purchase.help",
      "When a purchase is made or checkout flow is completed."
    ),
  },
  Schedule: {
    value: "SCHEDULE",
    icon: CalendarCheck2Icon,
    accept: "video/mp4",
    label: "Schedule",
    help: t(
      "flows:capi-conversion-events.Schedule.help",
      "When a person books an appointment to visit one of your locations."
    ),
  },
  // Search: {
  //   value: "SEARCH",
  //   icon: SearchIcon,
  //   accept: "video/mp4",
  //   label: "Search",
  //   help: t(
  //     "flows:capi-conversion-events.Search.help",
  //     "When a search is made."
  //   ),
  // },
  // StartTrial: {
  //   value: "START_TRIAL",
  //   icon: ChartBarIncreasingIcon,
  //   accept: "video/mp4",
  //   label: "StartTrial",
  //   help: t(
  //     "flows:capi-conversion-events.StartTrial.help",
  //     "When a person starts a free trial of a product or service you offer."
  //   ),
  // },
  // SubmitApplication: {
  //   value: "SUBMIT_APPLICATION",
  //   icon: SendIcon,
  //   accept: "video/mp4",
  //   label: "SubmitApplication",
  //   help: t(
  //     "flows:capi-conversion-events.SubmitApplication.help",
  //     "When a person applies for a product, service, or program you offer."
  //   ),
  // },
  // Subscribe: {
  //   value: "SUBSCRIBE",
  //   icon: RssIcon,
  //   accept: "video/mp4",
  //   label: "Subscribe",
  //   help: t(
  //     "flows:capi-conversion-events.Subscribe.help",
  //     "When a person applies to a start a paid subscription for a product or service you offer."
  //   ),
  // },
  ViewContent: {
    value: "VIEW_CONTENT",
    icon: EyeIcon,
    accept: "video/mp4",
    label: "ViewContent",
    help: t(
      "flows:capi-conversion-events.ViewContent.help",
      "A visit to a web page you care about (for example, a product page or landing page). ViewContent tells you if someone visits a web page's URL, but not what they see or do on that page."
    ),
  },
  CUSTOM: {
    value: "CUSTOM",
    icon: CodeIcon,
    accept: "video/mp4",
    label: t("flows:capi-conversion-events.CUSTOM.label", "Custom event"),
    help: t(
      "flows:capi-conversion-events.CUSTOM.help",
      "A custom event you'd like to send."
    ),
  },
};

export type CAPIConversionEvents = keyof typeof CAPI_CONVERSION_EVENTS;
