import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ExternalLinkIcon, PlusSmIcon, XIcon } from "@heroicons/react/outline";
import { ContactDetailRead } from "@hilos/types/private-schema";
import AddUserForm from "src/components/AddUserForm";
import UserAssignedList from "src/components/UserAssignedList";
import { UpdateContactFn } from "src/hooks/useInboxContactDetails";

interface ContactAssignmentListProps {
  contact: ContactDetailRead;
  onUpdateContact: UpdateContactFn;
}

function ContactAssignmentList({
  contact,
  onUpdateContact,
}: ContactAssignmentListProps) {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);

  const users = useMemo(
    () => contact.default_assignees ?? [],
    [contact.default_assignees]
  );

  const handleRemoveUser = useCallback(
    (userId: number) => {
      return () => {
        const nextUserIds = users
          .filter((user) => user.id !== userId)
          .map((user) => user.id);

        onUpdateContact({
          default_assignees: nextUserIds,
          overwrite_default_assignees: true,
        });
        setShowForm(false);
      };
    },
    [users, onUpdateContact]
  );

  const handleAddUser = useCallback(
    (userId?: number) => {
      if (userId) {
        const nextUserIds = users
          .filter((user) => user.id !== userId)
          .map((user) => user.id);

        nextUserIds.push(userId);

        onUpdateContact({
          default_assignees: nextUserIds,
        });
      }
      setShowForm(false);
    },
    [users, onUpdateContact]
  );

  return (
    <div data-tour="default-assignees" className="space-y-2">
      <div className="flex items-center justify-between px-3">
        <div className="flex items-center space-x-1 overflow-x-hidden">
          <span className="text-sm font-semibold uppercase tracking-wide text-gray-500 truncate">
            {t("inbox:default-assignees", "Default assignees")}
          </span>
          <a
            href="https://hilos.notion.site/Assignees-22cc686baeab42409c0e4539fd6180ca"
            target="_blank"
            rel="noreferrer"
          >
            <ExternalLinkIcon className="ml-1 h-4 w-4" />
          </a>
        </div>
        <div className="shrink-0">
          {showForm ? (
            <button
              className="inline-flex h-8 w-8 items-center justify-center rounded-full text-gray-500 transition duration-150 ease-in-out hover:bg-gray-300 focus:outline-none"
              type="button"
              onClick={(_) => setShowForm(false)}
            >
              <XIcon className="h-5 w-5" />
            </button>
          ) : (
            <button
              className="inline-flex h-8 w-8 items-center justify-center rounded-full text-gray-500 transition duration-150 ease-in-out hover:bg-gray-300 focus:outline-none"
              type="button"
              onClick={(_) => setShowForm(true)}
            >
              <PlusSmIcon className="h-5 w-5" />
            </button>
          )}
        </div>
      </div>

      <div className="overflow-y-auto bg-transparent">
        <ul className="divide-y divide-gray-200">
          <UserAssignedList users={users} onRemoveUser={handleRemoveUser} />
          {showForm && <AddUserForm onAddUser={handleAddUser} />}
        </ul>
      </div>
    </div>
  );
}

export default ContactAssignmentList;
