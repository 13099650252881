import { useState } from "react";
import { Formik } from "formik";
import * as meta from "../account/AccountMeta";
import OnboardingFormBasicInfo from "./OnboardingFormBasicInfo";

export const STEPS = {
  BASIC: "basic",
  PROFILING: "profiling",
};

export default function OnboardingForm({
  account,
  formSubmit,
  submitted,
  success,
}) {
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});

  const onSubmit = (values, setSubmitting) => {
    const trackingFields = [
      "utm_source",
      "utm_term",
      "utm_campaign",
      "utm_medium",
      "referrer",
    ];
    const newVals = { ...values };
    trackingFields.forEach((field) => {
      const item =
        localStorage.getItem(field) && localStorage.getItem(field) !== "null"
          ? localStorage.getItem(field).length > 255
            ? localStorage.getItem(field).slice(0, 255)
            : localStorage.getItem(field)
          : "";
      newVals[field] = item;
    });

    if (newVals[meta.FIELDS.onboarding_waba_provider_select.key] !== "OTHER") {
      newVals.onboarding_waba_provider =
        newVals[meta.FIELDS.onboarding_waba_provider_select.key];
    }
    if (!meta.FIELDS.onboarding_has_waba_provider.key) {
      newVals[meta.FIELDS.onboarding_waba_provider.key] = null;
    }
    formSubmit(
      newVals,
      setSubmitting,
      setBackendValidationErrors,
      setBackendError
    );
  };

  return (
    <Formik
      validationSchema={meta.createSchema}
      onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
      enableReinitialize={false}
      initialValues={account}
      validateOnMount={false}
    >
      {(formik) => (
        <form noValidate onSubmit={formik.handleSubmit}>
          <OnboardingFormBasicInfo
            formik={formik}
            success={success}
            submitted={submitted}
            backendError={backendError}
            backendValidationErrors={backendValidationErrors}
          />
        </form>
      )}
    </Formik>
  );
}
