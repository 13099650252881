import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import { ChannelAvailabilityData } from "@hilos/types/channel";
import DeleteObjectModal from "src/components/DeleteObjectModal";
import Loading from "src/components/Loading";
import PinIcon from "src/components/icons/PinIcon";
import UnpinIcon from "src/components/icons/UnpinIcon";
import { getQueryFilters } from "src/helpers/inbox";
import useHilosStore from "src/hooks/useHilosStore";
import { useInboxContactCounts } from "src/hooks/useInboxContactCounts";
import { InboxContactViewData } from "src/hooks/useInboxContactViews";
import { classNames } from "src/Helpers";
import MenuDropdown from "./Conversation/MenuDropdown";

export type onDeleteViewFn = (
  { obj }: { obj: InboxContactViewData },
  setIsSubmitting: Dispatch<SetStateAction<boolean>>,
  setBackendValidationErrors: Dispatch<SetStateAction<{}>>,
  setBackendError: Dispatch<SetStateAction<string>>,
  setSubmitted: Dispatch<SetStateAction<boolean>>,
  setSuccess: Dispatch<SetStateAction<boolean>>,
  setShowModal: Dispatch<SetStateAction<boolean>>
) => void;

interface ConversationListViewItemProps {
  view: InboxContactViewData;
  currentViewId: string | null;
  isInboxManager: boolean;
  currentAvailableChannels: ChannelAvailabilityData[];
  allowQueryCountWithLimit: boolean;
  onUpdate: (id: string) => void;
  onDelete: onDeleteViewFn;
  onSelect: (id: string) => void;
  onPinView: (id: string, isPinned: boolean) => void;
}

function ConversationListViewItem({
  view,
  currentViewId,
  isInboxManager,
  currentAvailableChannels,
  allowQueryCountWithLimit,
  onUpdate,
  onDelete,
  onSelect,
  onPinView,
}: ConversationListViewItemProps) {
  const { t } = useTranslation();
  const { session } = useHilosStore();
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteSubmitted, setDeleteSubmitted] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const filters = useMemo(() => {
    if (view.is_legacy) {
      return null;
    }

    try {
      const inboxParams = JSON.parse(view.data as string);
      const filters = getQueryFilters({
        session,
        inboxParams,
        currentAvailableChannels,
      });

      return filters;
    } catch (error) {
      console.error("Error parsing view data:", error);
      return null;
    }
  }, [session, view.is_legacy, view.data, currentAvailableChannels]);

  const isSelected = useMemo(() => {
    return currentViewId === view.id;
  }, [currentViewId, view.id]);

  const { count, loading } = useInboxContactCounts({
    filters,
    session,
    isSelected,
    allowQueryCountWithLimit,
  });

  const inboxContactsCount = useMemo(() => {
    if (view.is_legacy) {
      return view.conversations_count || 0;
    }

    return count || 0;
  }, [count, view.is_legacy, view.conversations_count]);

  return (
    <div
      title={view.description || undefined}
      className="group flex w-full flex-row items-center justify-between px-4 py-1"
    >
      <div
        onClick={() => onSelect(view.id)}
        className="flex h-8 w-full flex-1 cursor-pointer items-center justify-between break-all pr-7"
      >
        <p
          className={classNames(
            "truncate text-sm",
            view.id === currentViewId
              ? "font-bold text-indigo-600"
              : "font-medium text-gray-500 group-hover:text-indigo-600"
          )}
        >
          {t(view.name, view.name)}
        </p>
        <div className="flex min-w-fit flex-row">
          {Boolean(view.is_pinned) && (
            <PinIcon
              className="h-5 w-5"
              aria-hidden="true"
              onClick={() => {
                onPinView(view.id, view.is_pinned);
                onSelect(view.id);
              }}
            />
          )}
          <div
            className={classNames(
              "ml-1 min-w-[17px] rounded px-1 text-center",
              view.id === currentViewId
                ? "bg-indigo-500"
                : "bg-gray-200 group-hover:bg-indigo-500"
            )}
          >
            <span
              className={classNames(
                "text-sm font-medium",
                view.id === currentViewId
                  ? "text-white"
                  : "text-gray-600 group-hover:text-white"
              )}
            >
              {loading ? (
                <div className="w-4 h-4 overflow-hidden">
                  <Loading showText={false} />
                </div>
              ) : inboxContactsCount > 9999 ? (
                "9999+"
              ) : (
                inboxContactsCount
              )}
            </span>
          </div>
        </div>
      </div>
      <div className="absolute right-0 pr-2">
        <DeleteObjectModal
          obj={view}
          objDescription={view.name}
          actionDescription={
            view.is_public
              ? t(
                  "inbox:views.delete-action-description-public",
                  "When deleting this public view, it will be deleted for your whole team"
                )
              : t(
                  "inbox:views.delete-action-description-private",
                  "When deleting this private view, it will only be deleted for you"
                )
          }
          onDelete={(
            formData,
            setIsSubmitting,
            setBackendValidationErrors,
            setBackendError
          ) =>
            onDelete(
              formData,
              setIsSubmitting,
              setBackendValidationErrors,
              setBackendError,
              setDeleteSubmitted,
              setDeleteSuccess,
              setShowDeleteModal
            )
          }
          deleteSuccess={deleteSuccess}
          deleteSubmitted={deleteSubmitted}
          show={showDeleteModal}
          setShow={setShowDeleteModal}
        />
        <MenuDropdown
          options={[
            {
              id: "pin_view",
              label: view.is_pinned
                ? t("inbox:unpin-this-view")
                : t("inbox:pin-this-view"),
              icon: view.is_pinned ? (
                <UnpinIcon className="mr-2 h-5 w-5" aria-hidden="true" />
              ) : (
                <PinIcon className="mr-2 h-5 w-5" aria-hidden="true" />
              ),
              onClick: () => {
                onPinView(view.id, view.is_pinned);
              },
            },
            {
              id: "update_view",
              label: t("update"),
              disabled: !view.is_editable,
              icon: <PencilIcon className="mr-2 h-5 w-5" aria-hidden="true" />,
              onClick: (event) => {
                onUpdate(view.id);
                event.preventDefault();
              },
            },
            {
              id: "delete_view",
              label: t("delete"),
              disabled: !isInboxManager && !view.is_editable,
              icon: (
                <TrashIcon
                  className={classNames(
                    "mr-2 h-5 w-5",
                    isInboxManager || view.is_editable
                      ? "text-red-600"
                      : "text-gray-400"
                  )}
                  aria-hidden="true"
                />
              ),
              onClick: (event) => {
                setShowDeleteModal(true);
              },
            },
          ]}
        />
      </div>
    </div>
  );
}

export default ConversationListViewItem;
