import { gql } from "@apollo/client";
import memoize from "lodash/memoize";

export const INBOX_CONTACT_FIELDS_FRAGMENT = gql`
  fragment InboxContactFields on api_inboxcontact {
    id
    last_updated_on
    unseen_messages
    last_message_on
    is_unread
    channel {
      name
      channel_type
      channel_provider
    }
    contact {
      phone
      first_name
      last_name
      tags {
        tag {
          id
          name
        }
      }
    }
    last_conversation {
      status
      tags {
        tag {
          id
          name
        }
      }
      assigned {
        user {
          id
          email
          first_name
          last_name
          profile_image
        }
      }
    }
    last_message {
      status
      direction
      is_deleted
      msg_type
      body
      content
      timestamp
    }
    last_inbound_message_on
  }
`;

export const GET_INBOX_CONTACTS_QUERY = gql`
  ${INBOX_CONTACT_FIELDS_FRAGMENT}
  query GetInboxContacts(
    $filters: api_inboxcontact_bool_exp!
    $orderBy: [api_inboxcontact_order_by!]
  ) {
    api_inboxcontact(where: $filters, limit: 11, order_by: $orderBy) {
      ...InboxContactFields
    }
  }
`;

export const GET_INBOX_CONTACT_IDS_QUERY = `
  query GetInboxContactIds($filters: api_inboxcontact_bool_exp!) {
    api_inboxcontact(where: $filters) {
      id
    }
  }
`;

export const getInboxContactCountQuery = (
  allowQueryCountWithLimit: boolean
) => {
  const params = allowQueryCountWithLimit ? "limit: 1000" : "";

  return gql`
    query GetInboxContactsCount($filters: api_inboxcontact_bool_exp!) {
      api_inboxcontact_aggregate(where: $filters ${params}) {
        aggregate {
          count
        }
      }
    }
  `;
};

export const getInboxContactCountSubscription = (
  allowQueryCountWithLimit: boolean
) => {
  const params = allowQueryCountWithLimit ? "limit: 1000" : "";

  return gql`
      subscription OnInboxContactsCount($filters: api_inboxcontact_bool_exp!) {
        api_inboxcontact_aggregate(where: $filters ${params}) {
          aggregate {
            count
          }
        }
      }
    `;
};

export const INBOX_CONTACTS_UPDATE_LIVE_QUERY_SUBSCRIPTION = gql`
  ${INBOX_CONTACT_FIELDS_FRAGMENT}
  subscription OnInboxContactsUpdate(
    $filters: api_inboxcontact_bool_exp!
    $orderBy: [api_inboxcontact_order_by!]
  ) {
    api_inboxcontact(where: $filters, limit: 10, order_by: $orderBy) {
      ...InboxContactFields
    }
  }
`;

export const INBOX_CONTACTS_UPDATE_STREAMING_SUBSCRIPTION = gql`
  ${INBOX_CONTACT_FIELDS_FRAGMENT}
  subscription OnInboxContactsUpdateStreaming(
    $filters: api_inboxcontact_bool_exp!
    $last_updated_on: timestamptz!
  ) {
    api_inboxcontact_stream(
      where: $filters
      cursor: {
        initial_value: { last_updated_on: $last_updated_on }
        ordering: ASC
      }
      batch_size: 10
    ) {
      ...InboxContactFields
    }
  }
`;
