import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FormatOptionLabelMeta } from "react-select";
import axios from "axios";
import { debounce } from "lodash";
import { CursorPageData } from "@hilos/types/hilos";
import { CategoryEnum } from "@hilos/types/private-schema";
import { BodyComponent, WhatsAppTemplate } from "@hilos/types/wa/templates";
import * as templatesMeta from "src/containers/wa/WhatsAppTemplateMeta";
import useWhatsAppTemplate from "src/hooks/useWhatsAppTemplate";
import { API_ROUTES, buildAPIRoute } from "src/router/router";
import SelectorField from "../../components/Form/SelectorField";

interface WhatsAppTemplateOption {
  label: string;
  value: string;
  template: WhatsAppTemplate;
}

interface WhatsAppTemplateOptionGroup {
  label: string;
  icon: (props: any) => JSX.Element;
  options: WhatsAppTemplateOption[];
}

interface WhatsAppTemplateSelectProps {
  setSelectedTemplate?: (template: WhatsAppTemplate) => void;
  setRequiredFields?: (fields: string[]) => void | null;
  fieldLabel?: string | null;
  fieldName: string;
  fieldHelp?: string;
  renderFieldHelp?: JSX.Element;
  channel: number | null;
  categories?: CategoryEnum[] | null;
}

function WhatsAppTemplateSelectOptionLabel({ id }) {
  const template = useWhatsAppTemplate(id);
  if (!template) {
    return null;
  }
  const bodyComponent = template.components?.find((c) => c.type === "BODY") as
    | BodyComponent
    | undefined;

  return (
    <div className="py-2">
      <div className="flex items-center justify-between">
        <div>
          <h4 className="break-all font-medium">{template.name}</h4>
        </div>
      </div>
      <div className="truncate text-xs">{bodyComponent?.text || ""}</div>
    </div>
  );
}

export default function WhatsAppTemplateSelect({
  setSelectedTemplate,
  setRequiredFields,
  fieldName,
  fieldLabel,
  fieldHelp,
  renderFieldHelp,
  channel,
  categories,
}: WhatsAppTemplateSelectProps) {
  const { t } = useTranslation();

  const formatOptionLabel = useCallback(
    (
      option: WhatsAppTemplateOption,
      meta: FormatOptionLabelMeta<WhatsAppTemplateOption>
    ) => {
      if (!option.template) {
        return <WhatsAppTemplateSelectOptionLabel id={option.value} />;
      }

      const bodyComponent = option.template.components?.find(
        (c) => c.type === "BODY"
      ) as BodyComponent | undefined;

      return (
        <div className="py-2">
          <div className="flex items-center justify-between">
            <div>
              <h4 className="break-all font-medium">{option.template.name}</h4>
            </div>
          </div>
          <div className="truncate text-xs">{bodyComponent?.text || ""}</div>
        </div>
      );
    },
    []
  );

  const loadOptions = useCallback(
    debounce(
      (
        inputValue: string,
        callback: (options: WhatsAppTemplateOption[]) => void
      ) => {
        axios
          .get<CursorPageData<WhatsAppTemplate>>(
            buildAPIRoute(API_ROUTES.WHATSAPP_TEMPLATE_LIST),
            {
              params: {
                page_size: 20,
                channel,
                ordering: "-usage",
                category__in: categories ? categories.join(",") : null,
                search: inputValue,
                status: "approved",
              },
            }
          )
          .then(({ data }) => {
            const options = data.results.map((template) => ({
              label: template.name,
              value: template.id,
              template,
            }));

            callback(options);
          });
      },
      500
    ),
    [channel, categories]
  );

  const handleSelectOption = useCallback(
    (option: WhatsAppTemplateOption) => {
      if (option?.template) {
        setRequiredFields?.(
          templatesMeta.getTemplateVarsFromTemplate(option.template)
        );
        setSelectedTemplate?.(option.template);
      }
    },
    [setRequiredFields, setSelectedTemplate]
  );

  return (
    <div data-tour="broadcast-form-template">
      <SelectorField<WhatsAppTemplateOption, WhatsAppTemplateOptionGroup>
        isMulti={false}
        isCreatable={false}
        label={
          fieldLabel || t("templates:select.label", "WhatsApp template to use")
        }
        name={fieldName}
        help={fieldHelp}
        renderHelp={renderFieldHelp}
        loadOptions={loadOptions}
        defaultOptions={true}
        formatOptionLabel={formatOptionLabel}
        onSelect={handleSelectOption}
      />
    </div>
  );
}
