import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { DropzoneInputProps } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EmojiHappyIcon, MicrophoneIcon } from "@heroicons/react/outline";
import { EmojiClickData } from "emoji-picker-react";
import debounce from "lodash/debounce";
import { UpdateInboxContactFn } from "@hilos/hooks/useInboxContactDetails";
import { InboxContactRead } from "@hilos/types/private-schema";
import EmojiPicker from "src/components/EmojiPicker";
import { classNames } from "src/Helpers";
import BottomBarMessageQuickReplies, {
  BottomBarMessageQuickRepliesRef,
} from "./BottomBarMessageQuickReplies";
import BottomBarMoreOptionsMenu from "./BottomBarMoreOptionsMenu";
import ScheduledMessageMenu from "./ScheduledMessageMenu";

export type MessageMode =
  | "QUICK_REPLIES"
  | "SELECT_TEMPLATE"
  | "VOICE_RECORDING"
  | null;

export interface BottomBarMessageInputRef {
  focus: () => void;
  setMessage: (message: string) => void;
}

interface BottomBarMessageInputProps {
  inboxContact: InboxContactRead;
  currentMessageMode: MessageMode;
  getInputProps: <T extends DropzoneInputProps>(props?: T) => T;
  setCurrentMessageMode: (nextMessageMode: MessageMode) => void;
  onScheduleMessage: () => Promise<any>;
  onSendCurrentMessage: (message: string) => void;
  onUpdateInboxContact: UpdateInboxContactFn;
}

const TAB_KEY_CODE = 9;
const RETURN_KEY_CODE = 13;
const SHIFT_KEY_CODE = 16;

const BottomBarMessageInput = forwardRef<
  BottomBarMessageInputRef,
  BottomBarMessageInputProps
>(
  (
    {
      inboxContact,
      getInputProps,
      currentMessageMode,
      setCurrentMessageMode,
      onSendCurrentMessage,
      onUpdateInboxContact,
      onScheduleMessage,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const keyPressedRef = useRef(new Set());
    const quickRepliesRef = useRef<BottomBarMessageQuickRepliesRef>(null);
    const [message, setMessage] = useState(inboxContact.draft_message || "");
    const [submitting, setSubmitting] = useState(false);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);

    const handleUpdateDraftMessage = useMemo(
      () =>
        debounce((value: string) => {
          onUpdateInboxContact({
            draft_message: value || "",
          });
        }, 350),
      [onUpdateInboxContact]
    );

    const adjustTextAreaHeight = useCallback(() => {
      const textarea = textAreaRef.current;
      if (textarea) {
        textarea.style.height = "auto";
        textarea.style.height = `${Math.min(textarea.scrollHeight, 150)}px`;
      }
    }, []);

    const handleSetMessage = useCallback(
      (nextMessage: string) => {
        setMessage(nextMessage);
        handleUpdateDraftMessage(nextMessage);
      },
      [handleUpdateDraftMessage]
    );

    const handleSend = useCallback(async () => {
      if (
        message.length > 0 &&
        textAreaRef.current &&
        !textAreaRef.current.disabled
      ) {
        textAreaRef.current.disabled = true;
        setSubmitting(true);
        onSendCurrentMessage(message);
        setSubmitting(false);
        handleSetMessage("");
        if (textAreaRef.current) {
          textAreaRef.current.disabled = false;
          textAreaRef.current.focus();
        }
      }
    }, [message, onSendCurrentMessage, handleSetMessage]);

    const handleKeyUp = useCallback((event) => {
      if ([SHIFT_KEY_CODE, RETURN_KEY_CODE].includes(event.keyCode)) {
        keyPressedRef.current.delete(event.keyCode);
      }

      if (event.keyCode === SHIFT_KEY_CODE) {
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
    }, []);

    const handleKeyDown = useCallback(
      (event) => {
        const keyCode = event.keyCode;
        if (TAB_KEY_CODE === keyCode && quickRepliesRef.current) {
          event.preventDefault();
          quickRepliesRef.current.selectNextQuickReply();
        } else if (keyCode === RETURN_KEY_CODE && quickRepliesRef.current) {
          event.preventDefault();
          quickRepliesRef.current.setSelectedQuickReplyMessage();
        } else if ([SHIFT_KEY_CODE, RETURN_KEY_CODE].includes(keyCode)) {
          keyPressedRef.current.add(keyCode);
          if (
            keyCode === RETURN_KEY_CODE &&
            !keyPressedRef.current.has(SHIFT_KEY_CODE)
          ) {
            handleSend();
            event.preventDefault();
            event.stopPropagation();
            return false;
          }
        }

        if (keyCode === SHIFT_KEY_CODE) {
          event.preventDefault();
          event.stopPropagation();
          return false;
        }
      },
      [handleSend]
    );

    const handleSelectEmoji = useCallback(
      (data: EmojiClickData) => {
        const prevMessage = message || "";
        let currentSelectionIndex: number | null = null;
        if (textAreaRef.current) {
          currentSelectionIndex = textAreaRef.current.selectionStart;
        }
        let nextMessage = "";

        if (currentSelectionIndex !== null) {
          nextMessage =
            prevMessage.slice(0, currentSelectionIndex) +
            data.emoji +
            prevMessage.slice(currentSelectionIndex);
        } else {
          nextMessage = prevMessage + data.emoji;
        }

        handleSetMessage(nextMessage);
        setShowEmojiPicker(false);
      },
      [message, handleSetMessage]
    );

    const [searchQuickReplies, setSearchQuickReplies] = useState<string | null>(
      null
    );

    const handleChangeMessage = useCallback(
      (event) => {
        if (!submitting) {
          const nextMessage = event.target.value || "";
          handleSetMessage(nextMessage);

          if (nextMessage[0] === "/") {
            setSearchQuickReplies(nextMessage.slice(1) || "");
          } else if (searchQuickReplies !== null) {
            setSearchQuickReplies(null);
          }
        }
      },
      [submitting, handleSetMessage, searchQuickReplies]
    );

    const handleMoreOptionSelect = (
      option: "file" | "quick-reply" | "template"
    ) => {
      switch (option) {
        case "file":
          const fileInput = document.getElementById("message-file-upload");
          if (fileInput) fileInput.click();
          break;
        case "quick-reply":
          setCurrentMessageMode("QUICK_REPLIES");
          break;
        case "template":
          setCurrentMessageMode("SELECT_TEMPLATE");
          break;
      }
    };

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (textAreaRef.current) {
          textAreaRef.current.focus();
        }
      },
      setMessage: handleSetMessage,
    }));

    useEffect(() => {
      adjustTextAreaHeight();
    }, [message, adjustTextAreaHeight]);

    return (
      <div className="w-screen md:w-full px-2">
        {showEmojiPicker && (
          <EmojiPicker
            open={showEmojiPicker}
            onSelectEmoji={handleSelectEmoji}
          />
        )}
        {searchQuickReplies !== null && (
          <BottomBarMessageQuickReplies
            ref={quickRepliesRef}
            search={searchQuickReplies}
            inboxContact={inboxContact}
            onClose={() => setSearchQuickReplies(null)}
            onSetMessage={handleSetMessage}
          />
        )}
        <div className="relative flex items-center gap-2 pt-1 pb-2">
          <BottomBarMoreOptionsMenu
            onSelectOption={handleMoreOptionSelect}
            getInputProps={getInputProps}
          />

          <div className="relative flex-1">
            <textarea
              ref={textAreaRef}
              rows={1}
              name="message"
              id="message"
              onChange={(e) => {
                handleChangeMessage(e);
              }}
              className={classNames(
                "block w-full rounded-3xl resize-none border-0 bg-white px-4 py-2 pr-16 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                "min-h-[40px] max-h-[150px]",
                submitting ? "text-gray-400" : ""
              )}
              placeholder={t("add-your-message", "Add your message...")}
              value={message}
              onKeyUp={handleKeyUp}
              onKeyDown={handleKeyDown}
              maxLength={4096}
            />

            {message.length > 0 && inboxContact.last_inbound_message_on && (
              <div className="absolute right-9 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-500">
                <ScheduledMessageMenu
                  text={message}
                  inboxContactId={inboxContact.id}
                  lastInboundMessageOn={inboxContact.last_inbound_message_on}
                  onSetMessage={handleSetMessage}
                  onScheduleMessage={onScheduleMessage}
                />
              </div>
            )}

            <button
              type="button"
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-500"
            >
              <EmojiHappyIcon className="h-5 w-5" />
            </button>
          </div>

          <button
            type="button"
            onClick={
              message.length > 0
                ? handleSend
                : () => setCurrentMessageMode("VOICE_RECORDING")
            }
            className="flex h-10 w-10 items-center justify-center align-middle rounded-full bg-indigo-600 text-white hover:bg-indigo-700"
          >
            {message.length > 0 ? (
              <FontAwesomeIcon
                // @ts-ignore
                icon={faPaperPlane}
                className="-ml-0.5"
              />
            ) : (
              <MicrophoneIcon className="h-5 w-5" />
            )}
          </button>
        </div>
      </div>
    );
  }
);

export default BottomBarMessageInput;
