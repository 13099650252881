import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusSmIcon, XIcon } from "@heroicons/react/outline";
import {
  UserSimple,
  WorkflowTaskEdit,
  WorkflowTaskRead,
} from "@hilos/types/private-schema";
import AddUserForm from "src/components/AddUserForm";
import UserAssignedList from "src/components/UserAssignedList";
import useAccount from "src/hooks/useAccount";

interface TaskAssignedToProps {
  task: WorkflowTaskRead;
  onUpdateWorkflowTask: (task: Partial<WorkflowTaskEdit>) => void;
}

function TaskAssignedTo({ task, onUpdateWorkflowTask }: TaskAssignedToProps) {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const { members } = useAccount();

  const users = useMemo(() => {
    if (!task.assigned_users) {
      return [];
    }
    return members.filter(
      (user) =>
        // @ts-ignore
        user.id && task.assigned_users.includes(user.id)
    ) as unknown as UserSimple[];
  }, [members, task]);

  const handleRemoveUser = useCallback(
    (userId: number) => {
      return () => {
        const nextUserIds = users
          .filter((user) => user.id !== userId)
          .map((user) => user.id);

        onUpdateWorkflowTask({
          assigned_users: nextUserIds,
        });
        setShowForm(false);
      };
    },
    [users, onUpdateWorkflowTask]
  );

  const handleAddUser = useCallback(
    (userId?: number) => {
      if (userId) {
        const nextUserIds = users
          .filter((user) => user.id !== userId)
          .map((user) => user.id);

        nextUserIds.push(userId);

        onUpdateWorkflowTask({
          assigned_users: nextUserIds,
        });
      }
      setShowForm(false);
    },
    [users, onUpdateWorkflowTask]
  );

  return (
    <div data-tour="default-assignees" className="space-y-2">
      <div className="flex items-center justify-between px-3">
        <div className="flex items-center space-x-1 overflow-x-hidden">
          <span className="text-sm font-semibold uppercase tracking-wide text-gray-500 truncate">
            {t("workflows:task.assigned-to", "Assigned to")}
          </span>
        </div>
        <div className="shrink-0">
          {showForm ? (
            <button
              className="inline-flex h-8 w-8 items-center justify-center rounded-full text-gray-500 transition duration-150 ease-in-out hover:bg-gray-300 focus:outline-none"
              type="button"
              onClick={(_) => setShowForm(false)}
            >
              <XIcon className="h-5 w-5" />
            </button>
          ) : (
            <button
              className="inline-flex h-8 w-8 items-center justify-center rounded-full text-gray-500 transition duration-150 ease-in-out hover:bg-gray-300 focus:outline-none"
              type="button"
              onClick={(_) => setShowForm(true)}
            >
              <PlusSmIcon className="h-5 w-5" />
            </button>
          )}
        </div>
      </div>

      <div className="overflow-y-auto bg-transparent">
        <ul className="divide-y divide-gray-200">
          <UserAssignedList users={users} onRemoveUser={handleRemoveUser} />
          {showForm && <AddUserForm onAddUser={handleAddUser} />}
        </ul>
      </div>
    </div>
  );
}

export default TaskAssignedTo;
