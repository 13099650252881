import { useMemo } from "react";
import { QueryFunctionContext, useMutation, useQuery } from "react-query";
import axios from "axios";
import {
  InboxContactEdit,
  WorkflowTaskEdit,
  WorkflowTaskRead,
} from "@hilos/types/private-schema";
import { queryClient } from "src/HilosProvider";
import { API_ROUTES, buildAPIRoute } from "../router/router";

interface UseWorkflowTaskDetailsParams {
  taskId: string;
  workflowId: string;
}

const fetchWorkflowTask = async ({
  signal,
  queryKey: [_, taskId, workflowId],
}: QueryFunctionContext<[string, string, string]>) => {
  const { data } = await axios.get<WorkflowTaskRead>(
    buildAPIRoute(API_ROUTES.WORKFLOW_TASK_DETAIL, {
      ":id": taskId,
      ":workflow_id": workflowId,
    }),
    { signal }
  );

  return data;
};

const updateWorkflowTask =
  (taskId: string, workflowId: string) =>
  async (task: Partial<WorkflowTaskEdit>) => {
    const { data } = await axios.patch<WorkflowTaskRead>(
      buildAPIRoute(API_ROUTES.WORKFLOW_TASK_DETAIL, {
        ":id": taskId,
        ":workflow_id": workflowId,
      }),
      task
    );

    return data;
  };

const updateInboxContact =
  (inboxContactId: string | null) =>
  async (inboxContact: Partial<InboxContactEdit>) => {
    if (!inboxContactId) {
      return null;
    }
    const { data } = await axios.patch<InboxContactEdit>(
      buildAPIRoute(API_ROUTES.INBOX_CONTACT_DETAIL, {
        ":id": inboxContactId,
      }),
      inboxContact
    );
    return data;
  };

function useWorkflowTaskDetails({
  taskId,
  workflowId,
}: UseWorkflowTaskDetailsParams) {
  const {
    data: task,
    isError,
    isLoading,
    isFetching,
  } = useQuery({
    queryFn: fetchWorkflowTask,
    queryKey: ["workflow_task_read", taskId, workflowId],
    retry: false,
    refetchInterval: 20000,
  });

  const { mutateAsync: handleUpdateWorkflowTask } = useMutation({
    mutationFn: updateWorkflowTask(taskId, workflowId),
    mutationKey: ["workflow_task_edit", taskId, workflowId],
    onSuccess: (data) => {
      queryClient.setQueryData(
        ["workflow_task_read", taskId, workflowId],
        data
      );
    },
  });

  const inboxContactId = useMemo(() => task?.inbox_contact?.id || null, [task]);

  const { mutateAsync: handleUpdateInboxContact } = useMutation({
    mutationFn: updateInboxContact(inboxContactId),
    mutationKey: ["inbox_contact_edit", inboxContactId],
    onSuccess: (data) => {
      queryClient.setQueryData(["inbox_contact_details", inboxContactId], data);
      queryClient.setQueryData(
        ["workflow_task_read", taskId, workflowId],
        (prevData) => {
          if (!prevData) {
            return prevData;
          }
          return { ...prevData, inbox_contact: data };
        }
      );
    },
  });

  return {
    task,
    isError,
    isLoading,
    isFetching,
    handleUpdateWorkflowTask,
    handleUpdateInboxContact,
  };
}

export default useWorkflowTaskDetails;
