import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { FlowTemplateDetailRead } from "@hilos/types/private-schema";
import { API_ROUTES, buildAPIRoute } from "../router/router";

function useFlowTemplate(id: string | undefined) {
  const fetchFlowTemplate = useCallback(async () => {
    if (!id) {
      return null;
    }
    const { data } = await axios.get<FlowTemplateDetailRead>(
      buildAPIRoute(API_ROUTES.FLOW_TEMPLATE_DETAIL, {
        ":id": id,
      })
    );
    return data;
  }, [id]);

  const { data, refetch } = useQuery(
    ["flow_template_detail", id],
    fetchFlowTemplate
  );

  const updateFlowTemplate = useCallback(
    async (params: Partial<FlowTemplateDetailRead>) => {
      const { data } = await axios.patch<FlowTemplateDetailRead>(
        API_ROUTES.FLOW_TEMPLATE_DETAIL.replace(":id", id as string),
        params
      );
      if (data) {
        refetch();
        return data;
      }
    },
    [id, refetch]
  );

  const deleteFlowTemplate = useCallback(
    async (params: Partial<FlowTemplateDetailRead>) => {
      const { data } = await axios.patch<FlowTemplateDetailRead>(
        API_ROUTES.FLOW_TEMPLATE_DETAIL.replace(":id", id as string),
        params
      );
      if (data) {
        refetch();
        return data;
      }
    },
    [id, refetch]
  );

  return {
    flowTemplate: data,
    updateFlowTemplate,
    deleteFlowTemplate,
  };
}

export default useFlowTemplate;
