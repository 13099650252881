import { useCallback } from "react";
import { QueryFunctionContext, useQuery } from "react-query";
import { captureException } from "@sentry/react";
import axios from "axios";
import { ConversationsFilter } from "@hilos/containers/inbox/InboxMeta";
import { API_ROUTES, buildAPIRoute } from "src/router/router";

export interface InboxContactViewData {
  id: string;
  name: string;
  description: string;
  is_public: boolean;
  is_legacy: boolean;
  is_editable: boolean;
  is_pinned: boolean;
  data: string | { filters: ConversationsFilter[]; ordering: string | null };
  conversations_count?: number;
}

type FetchInboxContactViewsParams = QueryFunctionContext<
  [string, string, boolean]
>;

const fetchInboxContactViews = async ({
  signal,
  queryKey,
}: FetchInboxContactViewsParams) => {
  const { data } = await axios.get<InboxContactViewData[]>(
    API_ROUTES.INBOX_CONTACT_VIEW_LIST_CREATE,
    {
      signal,
      params: {
        search: queryKey[1] || undefined,
        show_views: !!queryKey[2],
      },
    }
  );

  return data;
};

interface useInboxContactViewsProps {
  showViews: boolean;
}

function useInboxContactViews({ showViews }: useInboxContactViewsProps) {
  const {
    data: inboxContactViews = [],
    refetch: refetchAllViews,
    isLoading: isLoadingAll,
  } = useQuery(["inbox_contact_views", "", showViews], fetchInboxContactViews, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 30,
  });

  const onGetView = useCallback(async (id: string) => {
    const result = await axios.get<InboxContactViewData>(
      buildAPIRoute(API_ROUTES.INBOX_CONTACT_VIEW_DETAIL, { ":id": id })
    );

    if (result.status === 200 && result.data) {
      return result.data;
    }
    return null;
  }, []);

  const onCreateView = useCallback(
    async (data: Partial<InboxContactViewData>) => {
      try {
        const result = await axios.post<InboxContactViewData>(
          API_ROUTES.INBOX_CONTACT_VIEW_LIST_CREATE,
          data,
          { validateStatus: (status) => status === 201 }
        );

        if (result.data) {
          refetchAllViews();
          return result.data;
        }
      } catch (error) {
        captureException(error);
      }
      return null;
    },
    [refetchAllViews]
  );

  const onUpdateView = useCallback(
    async (data: Partial<InboxContactViewData>) => {
      try {
        const result = await axios.patch<InboxContactViewData>(
          buildAPIRoute(API_ROUTES.INBOX_CONTACT_VIEW_DETAIL, {
            ":id": data.id,
          }),
          data,
          { validateStatus: (status) => status === 200 }
        );

        if (result.data) {
          refetchAllViews();
          return result.data;
        }
      } catch (error) {
        captureException(error);
      }
      return null;
    },
    [refetchAllViews]
  );

  const onDeleteView = useCallback(
    async (id: string) => {
      try {
        await axios.delete(
          buildAPIRoute(API_ROUTES.INBOX_CONTACT_VIEW_DETAIL, {
            ":id": id,
          }),
          { validateStatus: (status) => status === 204 }
        );

        refetchAllViews();
      } catch (error) {
        captureException(error);
      }
    },
    [refetchAllViews]
  );

  return {
    inboxContactViews,
    refetch: refetchAllViews,
    isLoading: isLoadingAll,
    onGetView,
    onCreateView,
    onUpdateView,
    onDeleteView,
  };
}

export default useInboxContactViews;
