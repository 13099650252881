import { useState } from "react";
import OnboardingInBrowser from "./OnboardingInBrowser";
import OnboardingInWA from "./OnboardingInWA";

export default function Welcome() {
  const [onboardingType, setOnboardingType] = useState(
    process.env.REACT_APP_ONBOARDING_TYPE || "BROWSER"
  );

  return (
    <div className="border-top border-top-2 flex w-full h-full items-center justify-center border-hilos bg-gray-50">
      <div className="h-full self-center w-full">
        {onboardingType === "WA" ? (
          <OnboardingInWA setOnboardingType={setOnboardingType} />
        ) : (
          <OnboardingInBrowser setOnboardingType={setOnboardingType} />
        )}
      </div>
    </div>
  );
}
