import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChatAltIcon, PlusSmIcon, XIcon } from "@heroicons/react/outline";
import { ContactDetailRead } from "@hilos/types/private-schema";
import { userToString } from "../../../../Helpers";
import ContactNoteForm from "./ContactNoteForm";

interface ContactNoteListProps {
  contact: ContactDetailRead;
  onAddNote: (note: string) => Promise<void>;
}

function ContactNoteList({ contact, onAddNote }: ContactNoteListProps) {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const currentNotes = useMemo(() => contact.notes ?? [], [contact.notes]);

  const handleAddNote = useCallback(
    async (note) => {
      await onAddNote(note);
      setShowForm(false);
    },
    [onAddNote]
  );

  return (
    <div className="space-y-2">
      <div className="flex justify-between items-center px-3">
        <div className="flex-1 text-sm font-semibold uppercase tracking-wide text-gray-500">
          {t("inbox:notes", "Notes")}
        </div>
        <div className="shrink-0">
          {showForm ? (
            <button
              className="inline-flex h-8 w-8 items-center justify-center rounded-full text-gray-500 transition duration-150 ease-in-out hover:bg-gray-300 focus:outline-none"
              type="button"
              onClick={(_) => setShowForm(false)}
            >
              <XIcon className="h-5 w-5" />
            </button>
          ) : (
            <button
              className="inline-flex h-8 w-8 items-center justify-center rounded-full text-gray-500 transition duration-150 ease-in-out hover:bg-gray-300 focus:outline-none"
              type="button"
              onClick={(_) => setShowForm(true)}
            >
              <PlusSmIcon className="h-5 w-5" />
            </button>
          )}
        </div>
      </div>

      {showForm ? (
        <ContactNoteForm onAddNote={handleAddNote} />
      ) : (
        <ul>
          {currentNotes.map((note, index) => (
            <li key={index} className="group px-3 pt-4 first:pt-0">
              <div className="relative">
                {index !== currentNotes.length - 1 && (
                  <span
                    className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                    aria-hidden="true"
                  />
                )}
                <div className="relative flex space-x-2 items-start">
                  <span className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-400 ring-4 ring-gray-100">
                    <ChatAltIcon
                      className="h-5 w-5 text-white"
                      aria-hidden="true"
                    />
                  </span>
                  <div className="flex flex-col min-w-0 flex-1">
                    <p className="text-xs font-medium text-gray-500 group-hover:text-gray-600">
                      {note.notes}
                    </p>
                    <div className="flex flex-row justify-between pr-2">
                      <p className="text-xs text-gray-400">
                        @{userToString(note.created_by)}
                      </p>
                      <time
                        dateTime={note.created_on}
                        className="whitespace-nowrap text-right text-xs font-medium text-gray-500"
                      >
                        {t("{{ date, d MMM }}", {
                          date: new Date(note.created_on),
                        })}
                      </time>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
export default ContactNoteList;
