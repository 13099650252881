import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DropzoneInputProps } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ClockIcon } from "@heroicons/react/outline";
import axios from "axios";
import { UpdateInboxContactFn } from "@hilos/hooks/useContactDetails";
import { CursorPageData } from "@hilos/types/hilos";
import {
  ConversationContent,
  InboxContactRead,
  WhatsAppMessage,
} from "@hilos/types/private-schema";
import Loading from "src/components/Loading";
import { hasItems } from "src/helpers/utils";
import {
  SendMessageFn,
  useConversationContent,
} from "src/hooks/useConversationContent";
import useInboxContactSync from "src/hooks/useInboxContactSync";
import { FileSource } from "src/hooks/useMediaDropzone";
import { useInboxContactScheduledMessages } from "src/hooks/useScheduledMessages";
import { API_ROUTES } from "src/router/router";
import ConversationBlocked from "./ConversationBlocked";
import ConversationContentList from "./ConversationContentList";
import ConversationFooter from "./ConversationFooter";
import DropzoneContainer from "./DropzoneContainer";
import FilesPreview from "./FilesPreview";
import ForwardMessageModal from "./ForwardMessageModal";

export interface HandleMountConversationContentPayload {
  id: string;
  messageId?: string | null;
  onFocus: () => void;
}

export type HandleMountConversationContent = (
  payload: HandleMountConversationContentPayload
) => () => void;

interface ConversationParams {
  inboxContact: InboxContactRead;
  uploading: boolean;
  files: FileSource[];
  isDragActive: boolean;
  isSubmitting: boolean;
  mustSendTemplate: boolean;
  focusedConversationContent: ConversationContent | null;
  onFilePicker: () => void;
  onMediaSubmit: () => Promise<void>;
  onCancelUpload: () => void;
  setShowConversationSearch?: (showConversationSearch: boolean) => void;
  getInputProps: <T extends DropzoneInputProps>(props?: T) => T;
  onSendMessage: SendMessageFn;
  onUpdateInboxContact: UpdateInboxContactFn;
  onFocusStartOfContent: (focused: boolean) => void;
  onFocusConversationContent: (
    focusedConversationContent: ConversationContent | null
  ) => void;
  onDisableMessageMediaUpload: (nextDisableMessageMediaUpload: boolean) => void;
}

function Conversation({
  inboxContact,
  uploading,
  files,
  isDragActive,
  isSubmitting,
  mustSendTemplate,
  focusedConversationContent,
  onFocusConversationContent,
  onFilePicker,
  onMediaSubmit,
  onCancelUpload,
  setShowConversationSearch,
  getInputProps,
  onSendMessage,
  onUpdateInboxContact,
  onFocusStartOfContent,
  onDisableMessageMediaUpload,
}: ConversationParams) {
  const { t } = useTranslation();
  const lastOutboundMessageIdRef = useRef<string | null>(null);
  const conversationContentByIdRef = useRef(new Map());
  const [selectedContextContent, setSelectedContextContent] =
    useState<ConversationContent | null>(null);
  const [selectedForwardMessage, setSelectedForwardMessage] =
    useState<WhatsAppMessage | null>(null);

  const inboxContactId = useMemo(() => inboxContact.id, [inboxContact]);

  const { data: scheduledMessages, refetch } = useInboxContactScheduledMessages(
    { inboxContactId }
  );

  const {
    pages,
    isLoading,
    isFetchingNextPage,
    isFetchingPreviousPage,
    isUpdatingCursor,
    hasNextPage,
    hasPreviousPage,
    hasFirstPageLoaded,
    showConversationEvents,
    handleNextPage,
    handlePreviousPage,
    handleChangeInitialCursor,
  } = useConversationContent({
    inboxContactId,
  });

  useInboxContactSync({ id: inboxContactId, showConversationEvents });

  const handleCloseConversationSearch = useCallback(() => {
    if (setShowConversationSearch) {
      setShowConversationSearch(false);
    }
    onFocusConversationContent(null);
  }, [setShowConversationSearch, onFocusConversationContent]);

  const handleFocusMessage = async (messageId: string) => {
    if (inboxContactId) {
      const { data } = await axios.get<CursorPageData<ConversationContent>>(
        API_ROUTES.CONVERSATION_CONTENT.replace(":id", inboxContactId),
        {
          params: {
            search: messageId,
            ignoreEvents: !showConversationEvents,
          },
        }
      );
      if (data && data.results) {
        onFocusConversationContent(data.results[0]);
      }
    }
  };

  const handleSendAndScrollToMessage = useCallback<SendMessageFn>(
    async (data) => {
      setSelectedContextContent(null);
      const result = await onSendMessage(data);
      if (!focusedConversationContent) {
        lastOutboundMessageIdRef.current = result.id;
      }
      return result;
    },
    [onSendMessage, focusedConversationContent]
  );

  const handleMountConversationContent =
    useCallback<HandleMountConversationContent>(
      ({ id, messageId, onFocus }) => {
        if (
          lastOutboundMessageIdRef.current &&
          lastOutboundMessageIdRef.current === messageId
        ) {
          onFocus();
          lastOutboundMessageIdRef.current = null;
        }
        conversationContentByIdRef.current.set(id, onFocus);
        return () => {
          conversationContentByIdRef.current.delete(id);
        };
      },
      []
    );

  useEffect(() => {
    if (
      focusedConversationContent &&
      !conversationContentByIdRef.current.has(focusedConversationContent.id) &&
      focusedConversationContent.timestamp
    ) {
      handleChangeInitialCursor(focusedConversationContent.timestamp);
    }
  }, [focusedConversationContent, handleChangeInitialCursor]);

  if (isLoading) {
    return <Loading />;
  }

  if (inboxContact.is_blocked) {
    return <ConversationBlocked />;
  }

  if (isDragActive) {
    return <DropzoneContainer />;
  }

  if (files.length > 0) {
    return (
      <FilesPreview
        files={files}
        uploading={uploading}
        isSubmitting={isSubmitting}
        onCancel={onCancelUpload}
        onFilePicker={onFilePicker}
        onMediaSubmit={onMediaSubmit}
      />
    );
  }

  return (
    <>
      <ConversationContentList
        inboxContact={inboxContact}
        pages={pages}
        className="bg-blue-gray-light bg-opacity-50 bg-chat-pattern bg-repeat bg-blend-darken p-3 sm:px-4"
        isUpdatingCursor={isUpdatingCursor}
        isFetchingNextPage={isFetchingNextPage}
        isFetchingPreviousPage={isFetchingPreviousPage}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        hasFirstPageLoaded={hasFirstPageLoaded}
        focusedConversationContent={focusedConversationContent}
        onNextPage={handleNextPage}
        onPreviousPage={handlePreviousPage}
        onFocusMessage={handleFocusMessage}
        onFocusStartOfContent={onFocusStartOfContent}
        onSelectContextContent={setSelectedContextContent}
        onSelectForwardMessage={setSelectedForwardMessage}
        onCloseConversationSearch={handleCloseConversationSearch}
        onMountConversationContent={handleMountConversationContent}
      />
      {hasItems(scheduledMessages) && (
        <div className="bg-blue-100 px-4 py-2 text-xs text-blue-800">
          <Link
            to="/inbox/scheduled-messages"
            className=" ml-1 flex font-medium"
          >
            <ClockIcon className="mr-2 h-4 w-4" />
            {t(
              "inbox:scheduled-message.conversation-footer",
              "This conversation has scheduled messages"
            )}{" "}
            <span className="ml-1 font-bold">({scheduledMessages.length})</span>
          </Link>
        </div>
      )}
      <ConversationFooter
        key={`conversation-footer-${inboxContactId}`}
        inboxContact={inboxContact}
        context={selectedContextContent}
        mustSendTemplate={mustSendTemplate}
        getInputProps={getInputProps}
        onSendMessage={handleSendAndScrollToMessage}
        onScheduleMessage={refetch}
        onUpdateInboxContact={onUpdateInboxContact}
        onSelectContextContent={setSelectedContextContent}
        onFocusConversationContent={onFocusConversationContent}
        onDisableMessageMediaUpload={onDisableMessageMediaUpload}
      />
      {selectedForwardMessage && (
        <ForwardMessageModal
          message={selectedForwardMessage}
          onSelectForwardMessage={setSelectedForwardMessage}
        />
      )}
    </>
  );
}

export default Conversation;
