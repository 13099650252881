import { useMemo } from "react";
import * as Tooltip from "@radix-ui/react-tooltip";
import { UserSimple } from "@hilos/types/private-schema";
import { userToString } from "src/Helpers";
import UserAvatar from "./UserAvatar";

interface AssigneeListProps {
  assignees?: { user: UserSimple }[] | null;
}

function AssigneeList({ assignees }: AssigneeListProps) {
  const firstAssignee = useMemo(() => assignees?.[0]?.user, [assignees]);

  const remainingAssignees = useMemo(
    () => assignees?.slice(1).filter((entry) => entry?.user) ?? [],
    [assignees]
  );

  const hasMoreAssignees = useMemo(
    () => remainingAssignees.length > 0,
    [remainingAssignees]
  );

  return !assignees?.length ? null : (
    <div className="flex items-center">
      {firstAssignee && (
        <UserAvatar
          data={firstAssignee}
          className="h-6 w-6 text-xs ring-2 ring-white"
        />
      )}

      {hasMoreAssignees && (
        <Tooltip.Provider delayDuration={300}>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <div className="-ml-1 flex h-6 w-6 items-center justify-center rounded-full bg-gray-100 text-xs font-medium text-gray-600 ring-2 ring-white cursor-pointer">
                +{remainingAssignees.length}
              </div>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content
                className="rounded-md bg-gray-900 p-3 text-sm text-white animate-fadeIn"
                sideOffset={5}
                align="start"
                alignOffset={-20}
              >
                <div className="flex flex-row items-center gap-1">
                  {remainingAssignees.map(({ user }, index) =>
                    user ? (
                      <div
                        key={user.id}
                        className="text-xs text-gray-300 whitespace-nowrap"
                      >
                        {userToString(user)}
                        {index < remainingAssignees.length - 1 && ", "}
                      </div>
                    ) : null
                  )}
                </div>
                <Tooltip.Arrow className="fill-gray-900" />
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      )}
    </div>
  );
}

export default AssigneeList;
