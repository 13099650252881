import { initReactI18next } from "react-i18next";
import { format as formatDate, formatDistance, formatRelative } from "date-fns";
import { enUS, es } from "date-fns/locale";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend, { HttpBackendOptions } from "i18next-http-backend";
import intervalPlural from "i18next-intervalplural-postprocessor";
import namespaces from "./i18nNamespaces.json";

const HilosLang = i18n.createInstance();

const formatRelativeLocaleEN = {
  lastWeek: "EEEE, LLLL do",
  yesterday: "'Yesterday'",
  today: "'Today'",
  tomorrow: "'Tomorrow'",
  nextWeek: "'Next' eeee",
  other: "PPP",
};

const formatRelativeLocaleES = {
  lastWeek: "EEEE, d 'de' LLLL",
  yesterday: "'Ayer'",
  today: "'Hoy'",
  tomorrow: "'Mañana'",
  nextWeek: "'Siguiente' eeee",
  other: "PPP",
};

const locales = {
  en: { ...enUS, formatRelative: (token) => formatRelativeLocaleEN[token] },
  es: { ...es, formatRelative: (token) => formatRelativeLocaleES[token] },
};

HilosLang.use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(intervalPlural)
  .init<HttpBackendOptions>({
    debug: false,
    fallbackLng: "en",
    load: "languageOnly",
    supportedLngs: ["en", "es"],
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        if (value instanceof Date && lng) {
          if (isNaN(value.getTime())) {
            return "";
          }
          const locale = locales[lng || "en"] || locales["en"];

          switch (format) {
            case "short":
              return formatDate(value, "P", { locale });
            case "long":
              return formatDate(value, "PPPP", { locale });
            case "relative":
              return formatRelative(value, new Date(), { locale });
            case "relative_lowercase":
              return formatRelative(value, new Date(), {
                locale,
              }).toLowerCase();
            case "ago":
              return formatDistance(value, new Date(), {
                locale,
                addSuffix: true,
              });
            default:
              return formatDate(value, format || "PPPP", { locale });
          }
        }

        return value;
      },
    },
    defaultNS: "translation",
    ns: namespaces,
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
      addPath: `${process.env.PUBLIC_URL}/locales/add/{{lng}}/{{ns}}`,
    },
    detection: {
      order: [
        "querystring",
        "cookie",
        "localStorage",
        "sessionStorage",
        "navigator",
        "htmlTag",
        "path",
        "subdomain",
      ],
      excludeCacheFor: ["cimode"],
      lookupQuerystring: "lng",
      lookupCookie: "i18next",
      lookupLocalStorage: "lng",
      lookupSessionStorage: "i18nextLng",
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,
      caches: ["localStorage", "cookie"],
    },
    react: {
      useSuspense: true,
    },
    returnNull: false,
    cleanCode: true,
  });

export const getTranslationPayload = (
  key: string,
  defaultValue: string | null = null
) => [key];

export default HilosLang;
