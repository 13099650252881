import { useCallback, useEffect, useMemo, useState } from "react";
import { DropzoneInputProps, useDropzone } from "react-dropzone";
import axios from "axios";
import { API_ROUTES } from "../router/router";
import { SendMessageFn } from "./useConversationContent";

interface UseMediaDropzoneParams {
  disabled?: boolean;
  handleSendMessage: SendMessageFn;
}

export interface FileSource {
  source: File;
  preview: string;
}

async function uploadPublicFile(file: FileSource) {
  const formData = new FormData();
  formData.append("uploaded_file", file.source);

  try {
    const { data } = await axios.post(API_ROUTES.PUBLIC_FILE_UPLOAD, formData);
    return data;
  } catch {}
  return null;
}

export const AUDIO_MIME_TYPES = [
  "audio/aac",
  "audio/mp4",
  "audio/mpeg",
  "audio/amr",
  "audio/ogg",
];
export const DOCUMENT_MIME_TYPES = [
  "text/plain",
  "text/csv",
  "application/csv",
  "application/pdf",
  "application/vnd.ms-powerpoint",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
export const IMAGE_MIME_TYPES = ["image/jpeg", "image/png"];
export const VIDEO_MIME_TYPES = ["video/mp4", "video/3gp"];
export const STICKER_MIME_TYPES = ["image/webp"];
export const SUPPORTED_MIME_TYPES = [
  ...AUDIO_MIME_TYPES,
  ...DOCUMENT_MIME_TYPES,
  ...IMAGE_MIME_TYPES,
  ...VIDEO_MIME_TYPES,
  ...STICKER_MIME_TYPES,
].join(",");

export const AUDIO_MAX_SIZE = 16 * Math.pow(1024, 2);
export const DOCUMENT_MAX_SIZE = 100 * Math.pow(1024, 2);
export const IMAGE_MAX_SIZE = 5 * Math.pow(1024, 2);
export const VIDEO_MAX_SIZE = 16 * Math.pow(1024, 2);
export const STICKER_MAX_SIZE = 500 * 1024;

const getMessageType = (contentType: string) => {
  if (contentType.includes("audio")) {
    return "audio";
  }
  if (contentType.includes("image")) {
    return "image";
  }
  if (contentType.includes("video")) {
    return "video";
  }
  if (contentType.includes("application") || contentType.includes("csv")) {
    return "document";
  }
  return undefined;
};

const hasAndroidFilePicker = () => {
  try {
    return Boolean(
      // @ts-ignore
      "Android" in window && "openFilePicker" in window.Android
    );
  } catch {}
  return false;
};

function useMediaDropzone({
  disabled = false,
  handleSendMessage,
}: UseMediaDropzoneParams) {
  const [files, setFiles] = useState<FileSource[]>([]);
  const [uploading, setUploading] = useState(false);

  const { getRootProps, getInputProps, isDragActive, isDragReject, open } =
    useDropzone({
      accept: SUPPORTED_MIME_TYPES,
      disabled,
      validator: (file) => {
        if (
          (AUDIO_MIME_TYPES.includes(file.type) &&
            file.size >= AUDIO_MAX_SIZE) ||
          (DOCUMENT_MIME_TYPES.includes(file.type) &&
            file.size >= DOCUMENT_MAX_SIZE) ||
          (IMAGE_MIME_TYPES.includes(file.type) &&
            file.size >= IMAGE_MAX_SIZE) ||
          (VIDEO_MIME_TYPES.includes(file.type) &&
            file.size >= VIDEO_MAX_SIZE) ||
          (STICKER_MIME_TYPES.includes(file.type) &&
            file.size >= STICKER_MAX_SIZE)
        ) {
          return {
            message: "File exceeds the allowed size",
            code: "file-too-large",
          };
        }
        return null;
      },
      onDrop: (acceptedFiles) => {
        const nextFiles = acceptedFiles.map((file) => ({
          // id: undefined,
          source: file,
          preview: URL.createObjectURL(file),
        }));
        setFiles([...files, ...nextFiles]);
      },
      noClick: true,
      noKeyboard: true,
    });

  const getCustomInputProps = useMemo(() => {
    if (!hasAndroidFilePicker()) {
      return getInputProps;
    }

    return <T extends DropzoneInputProps>(props: T = {} as T): T => ({
      ...props,
      onClick: () =>
        // @ts-ignore
        window.Android.openFilePicker(),
    });
  }, [getInputProps]);

  const onMediaSubmit = async () => {
    setUploading(true);
    try {
      // First, upload all images in parallel, and then send ordered messages with media
      const results = await Promise.all(files.map(uploadPublicFile));
      results.forEach((result, index) => {
        if (result) {
          handleSendMessage({
            msg_type: getMessageType(result.content_type),
            content_url: result.url,
            content_media: result.id,
            content_type: result.content_type,
          });
        }
      });
      setFiles([]);
    } catch (err) {
      console.log(err);
    }
    setUploading(false);
  };

  const onCancelUpload = useCallback(() => {
    setFiles([]);
  }, []);

  const onFilePicker = useCallback(() => {
    if (hasAndroidFilePicker()) {
      // @ts-ignore
      window.Android.openFilePicker();
    } else {
      open();
    }
  }, [open]);

  const onPaste = useCallback((event) => {
    const pastedFiles = [...event.clipboardData.files].map((file) => ({
      source: file,
      preview: URL.createObjectURL(file),
    }));
    setFiles(pastedFiles);
  }, []);

  const handleFileSelectedAndroid = useCallback(
    (fileData: string, contentType: string, fileName: string) => {
      const byteCharacters = window.atob(fileData);
      const byteArrays: number[] = [];

      for (let i = 0; i < byteCharacters.length; i++) {
        byteArrays.push(byteCharacters.charCodeAt(i));
      }

      const byteArray = new Uint8Array(byteArrays);
      const selectedFile = new File([byteArray], fileName, {
        type: contentType,
      });

      setFiles((currentFiles) => [
        ...currentFiles,
        {
          source: selectedFile,
          preview: URL.createObjectURL(selectedFile),
        },
      ]);
    },
    []
  );

  useEffect(() => {
    window["returnFileSelectedAndroid"] = handleFileSelectedAndroid;
    return () => {
      delete window["returnFileSelectedAndroid"];
    };
  }, [handleFileSelectedAndroid]);

  return {
    files,
    setFiles,
    uploading,
    getRootProps,
    getInputProps: getCustomInputProps,
    isDragActive,
    isDragReject,
    onPaste,
    onFilePicker,
    onMediaSubmit,
    onCancelUpload,
  };
}

export default useMediaDropzone;
