import {
  format,
  formatDistanceToNow,
  isThisWeek,
  isToday,
  isValid,
  isYesterday,
} from "date-fns";

/**
 * Function to get the timestamp of a string, converting it to Date and checking if it is valid
 * @param data `string` to be converted to Date @see Date
 * @returns the timestamp of the data if the string is a valid date, otherwise null
 */
export function getTimestamp(data: string) {
  const date = new Date(data);
  if (isValid(date)) {
    return date;
  }
  return null;
}

/** Function to get the time from a timestamp in the form of HH:mm
 * @param data `string` to be converted to passed to getTimestamp
 * @see getTimestamp
 * @returns the time of the timestamp if the string is a valid date, otherwise null
 */
export function getTimeFromTimestamp(data: string) {
  const timestamp = getTimestamp(data);
  if (timestamp) {
    return format(timestamp, "HH:mm"); // "p");
  }
  return null;
}

/** Function to get the date from a timestamp in the form of dd/MMM
 * @example getDateFromTimestamp("2021-01-01T00:00:00.000Z") // "01/Jan"
 * @param data `string` to be converted to passed to getTimestamp
 * @see getTimestamp
 * @returns the date `string` of the timestamp using date-fns format if the string is a valid date, otherwise `null`
 * @see https://date-fns.org/v2.30.0/docs/format
 */
export function getDateFromTimestamp(data: string) {
  const timestamp = getTimestamp(data);
  if (timestamp) {
    return format(timestamp, "dd/MMM"); // "p");
  }
  return null;
}

/** Function to retrieve the day from a timestamp
 * @example getDayFromTimestamp("2021-01-01T00:00:00.000Z") // "Friday"
 * @param data `string` to be converted to passed to getTimestamp
 * @see getTimestamp
 * @returns the day of the timestamp if the string is a valid date, otherwise null.
 * Can be in the form of `HH:mm`, `Yesterday`, `Monday`, `1/1/2021` depending if the day is today, yesterday, this week or not
 * Checks are done using date-fns isToday, isYesterday and isThisWeek and format is used to format the date
 * @see https://date-fns.org/v2.30.0/docs/isToday
 * @see https://date-fns.org/v2.30.0/docs/isYesterday
 * @see https://date-fns.org/v2.30.0/docs/isThisWeek
 * @see https://date-fns.org/v2.30.0/docs/format
 */
export function getDateTimeFromTimestamp(data: string) {
  const timestamp = getTimestamp(data);

  if (timestamp) {
    if (isToday(timestamp)) {
      return format(timestamp, "HH:mm");
    }
    if (isYesterday(timestamp)) {
      return "Yesterday";
    }
    if (isThisWeek(timestamp, { weekStartsOn: 1 })) {
      return format(timestamp, "EEEE");
    }
    return format(timestamp, "d/M/yyyy");
  }

  return null;
}
/**
 * Calculates the distance from now to the timestamp using date-fns
 * @param data string to be converted to passed to getTimestamp
 * @see getTimestamp
 * @see formatDistanceToNow
 * @returns `string` or `null`
 * @see https://date-fns.org/v2.30.0/docs/formatDistanceToNow
 */
export function getDistanceFromNow(data: string) {
  const timestamp = getTimestamp(data);
  if (timestamp) {
    return formatDistanceToNow(timestamp, { addSuffix: true });
  }
  return null;
}

export function parseDateFromData(data) {
  try {
    const date = data instanceof Date ? data : new Date(data);

    if (isValid(date)) {
      return date;
    }
  } catch (error) {
    console.error("Error parsing date:", error);
  }
  return null;
}
