import { useMemo, useRef } from "react";
import { motion } from "framer-motion";
import { ChannelAvailabilityData } from "@hilos/types/channel";
import { InboxContactViewData } from "src/hooks/useInboxContactViews";
import ViewButton from "./ViewButton";

interface PinnedViewsProps {
  views: InboxContactViewData[];
  currentView: InboxContactViewData | null;
  currentViewId: string | null;
  currentAvailableChannels: ChannelAvailabilityData[];
  allowQueryCountWithLimit: boolean;
  onSelectView: (id: string) => void;
}

export default function PinnedViews({
  views,
  currentView,
  currentViewId,
  currentAvailableChannels,
  allowQueryCountWithLimit,
  onSelectView,
}: PinnedViewsProps) {
  const containerRef = useRef<HTMLDivElement>(null);

  const displayedViews = useMemo(() => {
    if (!currentView || views.some((v) => v.id === currentView.id)) {
      return views;
    }
    return [currentView, ...views];
  }, [views, currentView]);

  if (!displayedViews.length) return null;

  return (
    <div
      ref={containerRef}
      className="overflow-hidden bg-white border-b border-gray-200"
    >
      <motion.div
        className="flex gap-2 px-4 py-2 cursor-grab w-[max-content]"
        drag="x"
        dragConstraints={containerRef}
        dragElastic={0}
        dragMomentum={false}
        dragPropagation
        whileDrag={{ cursor: "grabbing", pointerEvents: "none" }}
      >
        {displayedViews.map((view) => (
          <ViewButton
            key={view.id}
            view={view}
            isSelected={currentViewId === view.id}
            currentAvailableChannels={currentAvailableChannels}
            allowQueryCountWithLimit={allowQueryCountWithLimit}
            onClick={onSelectView}
            isTemporary={!views.some((v) => v.id === view.id)}
          />
        ))}
      </motion.div>
    </div>
  );
}
