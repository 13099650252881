import { memo, useEffect, useRef, useState } from "react";
import { autoScrollForElements } from "@atlaskit/pragmatic-drag-and-drop-auto-scroll/element";
import { Edge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import { dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { PlusSmIcon } from "@heroicons/react/outline";
import { motion } from "framer-motion";
import invariant from "tiny-invariant";
import { classNames } from "src/Helpers";
import {
  WorkflowTaskItem,
  useWorkflowBoardContext,
} from "../WorkflowBoardContext";
import { SelectedWorkflowTask } from "../WorkflowBoardData";
import { Card } from "./Card";

/**
 * Note: not making `'is-dragging'` a `State` as it is
 * a _parallel_ state to `'is-column-over'`.
 *
 * Our board allows you to be over the column that is currently dragging
 */
type ColumnState =
  | { type: "idle" }
  | { type: "is-card-over" }
  | { type: "is-column-over"; closestEdge: Edge | null };

// preventing re-renders with stable state objects
const idle: ColumnState = { type: "idle" };
const isCardOver: ColumnState = { type: "is-card-over" };

export const Column = memo(function Column({
  id: columnId,
  title,
  tasks,
  selectedTaskId = null,
  onClickCard,
  onShowAddTask,
}: {
  id: string;
  title: string;
  tasks: Map<string, WorkflowTaskItem>;
  selectedTaskId?: string | null;
  onClickCard: (task: SelectedWorkflowTask | null) => void;
  onShowAddTask: (id: string) => void;
}) {
  const columnRef = useRef<HTMLDivElement | null>(null);
  const headerRef = useRef<HTMLDivElement | null>(null);
  const cardListRef = useRef<HTMLDivElement | null>(null);
  const [state, setState] = useState<ColumnState>(idle);

  const instanceId = useWorkflowBoardContext((state) => state.instanceId);
  const registerColumn = useWorkflowBoardContext(
    (state) => state.registerColumn
  );

  useEffect(() => {
    invariant(columnRef.current);
    invariant(headerRef.current);
    invariant(cardListRef.current);
    return combine(
      registerColumn({
        columnId,
        entry: {
          element: columnRef.current,
        },
      }),
      dropTargetForElements({
        element: cardListRef.current,
        getData: () => ({ columnId }),
        canDrop: ({ source }) => {
          return (
            source.data.instanceId === instanceId &&
            source.data.type === "card" &&
            source.data.state !== columnId
          );
        },
        getIsSticky: () => true,
        onDragEnter: () => setState(isCardOver),
        onDragLeave: () => setState(idle),
        onDragStart: () => setState(isCardOver),
        onDrop: () => setState(idle),
      }),
      autoScrollForElements({
        element: cardListRef.current,
        canScroll: ({ source }) =>
          source.data.instanceId === instanceId && source.data.type === "card",
      })
    );
  }, [columnId, registerColumn, instanceId]);

  return (
    <motion.div
      ref={columnRef}
      itemID={`column-${columnId}`}
      initial={{ opacity: 0, width: 0 }}
      animate={{ opacity: 1, width: "auto" }}
      exit={{
        opacity: 0,
        width: 0,
        marginLeft: 0,
        marginRight: 0,
        overflow: "hidden",
      }}
      transition={{
        opacity: { duration: 0.2 },
        width: { duration: 0.4 },
        layout: { duration: 0.3 },
      }}
      layout
      className={classNames(
        "min-w-min rounded-lg bg-gray-100 overflow-hidden",
        state.type === "is-card-over" && "bg-gray-200",
        selectedTaskId ? "bg-gray-50 rounded-none hidden sm:flex" : "flex"
      )}
    >
      <div
        className={classNames(
          "flex flex-col overflow-hidden",
          selectedTaskId ? "min-w-60 min-h-0 grow" : "w-60"
        )}
      >
        <div
          ref={headerRef}
          itemID={`column-header-${columnId}`}
          className={classNames(
            "flex row-auto w-full p-2 text-gray-800 bg-gray-100 select-none justify-between items-center",
            state.type === "is-card-over" && "bg-gray-200",
            selectedTaskId && "bg-gray-50"
          )}
        >
          <h6 itemID={`column-header-title-${columnId}`}>{title}</h6>
          <button
            className=""
            type="button"
            onClick={() => onShowAddTask(columnId)}
          >
            <PlusSmIcon className="h-5 w-5 text-gray-800" />
          </button>
        </div>
        <div
          ref={cardListRef}
          className="h-full w-full p-2 space-y-2 overflow-y-auto"
        >
          {Array.from(tasks, ([taskId, task]) => (
            <Card
              key={taskId}
              item={task}
              selected={selectedTaskId === taskId}
              onClickCard={onClickCard}
            />
          ))}
        </div>
      </div>
    </motion.div>
  );
});
