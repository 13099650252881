import { useCallback, useMemo, useRef, useState } from "react";
import { Outlet, useMatch, useParams } from "react-router-dom";
import { differenceInDays, isValid } from "date-fns";
import { motion } from "framer-motion";
import { ConversationContent } from "@hilos/types/private-schema";
import Loading from "src/components/Loading";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "src/components/ui/resizable";
import { useUpdateConversationContent } from "src/hooks/useConversationContent";
import useMediaDropzone from "src/hooks/useMediaDropzone";
import useWorkflowTaskDetails from "src/hooks/useWorkflowTaskDetails";
import { classNames } from "src/Helpers";
import Conversation from "../inbox/Conversation/Conversation";
import WorkflowBoardTaskHeader from "./WorkflowBoardTaskHeader";

export default function WorkflowBoardTask() {
  const isConversationVisible = useMatch(
    "/workflow/:workflowId/board/:stateId/task/:taskId"
  );
  const { taskId, workflowId } = useParams() as {
    taskId: string;
    workflowId: string;
  };

  const {
    task,
    isLoading: isLoadingTask,
    handleUpdateInboxContact,
  } = useWorkflowTaskDetails({
    taskId,
    workflowId,
  });

  const isLastMessageFocusedRef = useRef(false);
  const [focusedConversationContent, setFocusedConversationContent] =
    useState<ConversationContent | null>(null);
  const [disableMessageMediaUpload, setDisableMessageMediaUpload] =
    useState(false);

  const { isSubmitting, handleSendMessage } = useUpdateConversationContent({
    inboxContactId: task && task.inbox_contact.id,
  });

  const mustSendTemplate = useMemo(() => {
    if (
      task &&
      task.inbox_contact &&
      task.inbox_contact.last_inbound_message_on
    ) {
      const lastInboundMessageTimestamp = new Date(
        task.inbox_contact.last_inbound_message_on
      );

      return (
        !isValid(lastInboundMessageTimestamp) ||
        differenceInDays(new Date(), lastInboundMessageTimestamp) >= 1
      );
    }
    return true;
  }, [task]);

  const {
    files,
    uploading,
    getRootProps,
    getInputProps,
    isDragActive,
    onPaste,
    onFilePicker,
    onMediaSubmit,
    onCancelUpload,
  } = useMediaDropzone({
    disabled: mustSendTemplate || disableMessageMediaUpload,
    handleSendMessage,
  });

  const handleDisableMessageMediaUpload = useCallback(
    (nextDisableMessageMediaUpload: boolean) => {
      setDisableMessageMediaUpload(nextDisableMessageMediaUpload);
    },
    []
  );

  const handleFocusStartOfContent = useCallback(
    (isLastMessageFocused = false) => {
      if (isLastMessageFocusedRef.current !== isLastMessageFocused) {
        isLastMessageFocusedRef.current = isLastMessageFocused;

        if (isLastMessageFocused) {
          handleUpdateInboxContact({
            is_unread: false,
            unseen_messages: 0,
          });
        }
      }
    },
    [handleUpdateInboxContact]
  );

  if (isLoadingTask) {
    return <Loading />;
  }

  if (!task) {
    return null;
  }

  return (
    <div className="sticky w-full h-full" onPaste={onPaste}>
      <div className="flex w-full h-full bg-gray-100 overflow-hidden">
        <motion.div
          initial={{ opacity: 0, width: 0 }}
          animate={{ opacity: 1, width: "auto" }}
          exit={{
            opacity: 0,
            width: 0,
          }}
          transition={{
            opacity: { duration: 0.2 },
            width: { duration: 0.4 },
            layout: { duration: 0.3 },
          }}
          layout
          className="flex flex-1 overflow-hidden"
        >
          <ResizablePanelGroup direction="horizontal" className="w-full h-full">
            <ResizablePanel
              {...getRootProps({
                className: classNames(
                  isConversationVisible
                    ? "flex"
                    : "hidden lg:flex lg:min-w-96 lg:w-full",
                  "cursor-default"
                ),
              })}
            >
              <div className="flex flex-col w-full h-full">
                <WorkflowBoardTaskHeader
                  taskId={taskId}
                  workflowId={workflowId}
                  inboxContact={task.inbox_contact}
                  onUpdateInboxContact={handleUpdateInboxContact}
                />
                <Conversation
                  uploading={uploading}
                  inboxContact={task.inbox_contact}
                  focusedConversationContent={focusedConversationContent}
                  files={files}
                  isDragActive={isDragActive}
                  isSubmitting={isSubmitting}
                  mustSendTemplate={mustSendTemplate}
                  onFilePicker={onFilePicker}
                  onMediaSubmit={onMediaSubmit}
                  onCancelUpload={onCancelUpload}
                  getInputProps={getInputProps}
                  onSendMessage={handleSendMessage}
                  onUpdateInboxContact={handleUpdateInboxContact}
                  onFocusStartOfContent={handleFocusStartOfContent}
                  onFocusConversationContent={setFocusedConversationContent}
                  onDisableMessageMediaUpload={handleDisableMessageMediaUpload}
                />
              </div>
            </ResizablePanel>
            {!isConversationVisible && (
              <>
                <ResizableHandle className="hidden lg:flex" withHandle />
                <ResizablePanel defaultSize={20} className="min-w-72 shrink-0">
                  <Outlet
                    context={{
                      focusedConversationContent,
                      setFocusedConversationContent,
                    }}
                  />
                </ResizablePanel>
              </>
            )}
          </ResizablePanelGroup>
        </motion.div>
      </div>
    </div>
  );
}
