import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useMatch } from "react-router-dom";
import { ChevronLeft, MoreVertical, Search } from "lucide-react";
import { UpdateInboxContactFn } from "@hilos/hooks/useInboxContactDetails";
import { InboxContactRead } from "@hilos/types/private-schema";
import { Button } from "src/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import useHilosStore from "src/hooks/useHilosStore";
import useWorkflowTaskDetails from "src/hooks/useWorkflowTaskDetails";
import { contactToString, formatPhone } from "src/Helpers";
import { buildRoute } from "src/router/router";
import SelectStatus from "./SelectStatus";

interface WorkflowBoardTaskProps {
  taskId: string;
  workflowId: string;
  inboxContact: InboxContactRead;
  onUpdateInboxContact: UpdateInboxContactFn;
}

interface MenuDropdownOption
  extends React.ComponentPropsWithoutRef<typeof DropdownMenuItem> {
  label: string;
}

interface MenuDropdownOptionsProps {
  options: MenuDropdownOption[];
}

const DropdownMenuOptions = ({ options }: MenuDropdownOptionsProps) => (
  <>
    {options.map(({ label, ...props }) => (
      <DropdownMenuItem {...props}>{label}</DropdownMenuItem>
    ))}
  </>
);

export default function WorkflowBoardTaskHeader({
  taskId,
  workflowId,
  inboxContact,
  onUpdateInboxContact,
}: WorkflowBoardTaskProps) {
  const [t] = useTranslation();
  const isConversationSearchVisible = useMatch(
    "/workflow/:workflowId/board/:stateId/task/:taskId/search"
  );
  const { task, handleUpdateWorkflowTask } = useWorkflowTaskDetails({
    taskId,
    workflowId,
  });

  const { updateSession } = useHilosStore();
  const showConversationEvents = useHilosStore(
    ({ session }) => session?.show_conversation_events ?? true
  );

  const [isSilenced, isArchived, isBlocked, isUnread] = useMemo(
    () => [
      inboxContact.is_silenced,
      inboxContact.is_archived,
      inboxContact.is_blocked,
      inboxContact.is_unread,
    ],
    [inboxContact]
  );

  const options = useMemo(() => {
    const nextOptions: MenuDropdownOption[] = [
      {
        label: isSilenced
          ? t("workflows:task.conversation.unmute", "Unmute")
          : t("workflows:task.conversation.mute", "Mute"),
        onClick: () =>
          onUpdateInboxContact({
            is_silenced: !isSilenced,
          }),
      },
      {
        label: isArchived
          ? t("workflows:task.conversation.unarchive", "Unarchive")
          : t("workflows:task.conversation.archive", "Archive"),
        onClick: () =>
          onUpdateInboxContact({
            is_archived: !isArchived,
          }),
      },
      {
        label: isBlocked
          ? t("workflows:task.conversation.remove-block", "Remove block")
          : t("workflows:task.conversation.block", "Block"),
        onClick: () =>
          onUpdateInboxContact({
            is_blocked: !isBlocked,
          }),
      },
      {
        label: showConversationEvents
          ? t("workflows:task.conversation.hide-events", "Hide Events")
          : t("workflows:task.conversation.show-events", "Show Events"),
        onClick: () =>
          updateSession({
            show_conversation_events: !showConversationEvents,
          }),
      },
    ];

    if (!isUnread) {
      nextOptions.push({
        label: t(
          "workflows:task.conversation.mark-as-unread",
          "Mark as unread"
        ),
        onClick: () =>
          onUpdateInboxContact({
            is_unread: true,
          }),
      });
    }

    return nextOptions;
  }, [
    t,
    isSilenced,
    isArchived,
    isBlocked,
    isUnread,
    showConversationEvents,
    updateSession,
    onUpdateInboxContact,
  ]);

  if (!task) {
    return null;
  }

  return (
    <div className="flex items-center p-2 bg-white border-b border-gray-200">
      <Button variant="ghost" size="icon" className="px-1 py-1">
        <Link to={buildRoute("workflow-board", { id: workflowId })}>
          <ChevronLeft className="h-4 w-4" />
        </Link>
      </Button>
      <Button variant="ghost" className="pl-1" asChild>
        <Link to="details">
          <div className="grid flex-1 text-left text-md leading-tight">
            <span className="truncate font-semibold">
              {contactToString(task.inbox_contact.contact)}
            </span>
            <span className="truncate text-xs">
              {formatPhone(task.inbox_contact.contact.phone, true, true)}
            </span>
          </div>
        </Link>
      </Button>
      <div className="ml-auto flex items-center gap-2">
        <SelectStatus
          currentStateId={task.state}
          onUpdateWorkflowTask={handleUpdateWorkflowTask}
        />
        {!isConversationSearchVisible && (
          <Button variant="ghost" size="icon" asChild>
            <Link to="search">
              <Search className="h-4 w-4" />
              <span className="sr-only">Search</span>
            </Link>
          </Button>
        )}
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="icon">
              <MoreVertical className="h-4 w-4" />
              <span className="sr-only">More</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuOptions options={options} />
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
}
