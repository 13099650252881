import { useTranslation } from "react-i18next";
import { XIcon } from "@heroicons/react/outline";
import { UserSimple } from "@hilos/types/private-schema";
import { userAvatar, userToString } from "src/Helpers";

interface UserAssignedListProps {
  users: UserSimple[];
  onRemoveUser: (userId: number) => () => void;
}

function UserAssignedList({ users, onRemoveUser }: UserAssignedListProps) {
  const { t } = useTranslation();

  if (!users.length) {
    return (
      <p className="px-3 text-xs text-gray-400">
        {t("no-assignees", "No one is assigned yet.")}
      </p>
    );
  }

  return (
    <>
      {users.map((user, idx) => (
        <li key={idx} className="py-1 px-3 hover:bg-gray-200">
          <div className="flex items-center space-x-2">
            <div className="flex-shrink-0">
              {userAvatar(
                user,
                `h-6 w-6 text-xs ring-2 ring-gray-100 group-hover:ring-gray-200`
              )}
            </div>
            <div className="min-w-0 flex-1">
              <p className="truncate text-sm font-medium text-gray-900">
                {userToString(user)}
              </p>
            </div>
            <div>
              <button
                type="button"
                className="inline-flex h-8 w-8 items-center justify-center rounded-full text-gray-400 transition duration-150 ease-in-out focus:outline-none"
                onClick={onRemoveUser(user.id)}
              >
                <XIcon className="h-4 w-4 text-gray-500" />
              </button>
            </div>
          </div>
        </li>
      ))}
    </>
  );
}

export default UserAssignedList;
