import { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  Card,
  LineChart,
  Metric,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  Text,
} from "@tremor/react";
import Loading from "src/components/Loading";
import useDateFnsFormatLocale from "src/hooks/useDateFnsFormatLocale";
import { useInboxUsageSummaryAnalytics } from "src/hooks/useInboxUsageSummaryAnalytics";
import { durationFormatter, numberFormatter, sumArray } from "../Helpers";

interface InboxSummaryMultiChartProps {
  params: string;
}

export function InboxSummaryMultiChart({
  params,
}: InboxSummaryMultiChartProps) {
  const { t } = useTranslation();
  const { inboxUsageSummary: dailyInboxUsageSummary } =
    useInboxUsageSummaryAnalytics(params + "&period_type_type=DAY");
  const { formatWithLocale } = useDateFnsFormatLocale();

  const formatDateInCurrentTimezone = useCallback(
    (periodDate: string) => {
      const date = new Date();
      // date.setHours(12, 0, 0, 0); // Set the time to 12:00 PM
      const timezoneTime = date.toISOString();

      const timezoneTimeHours = timezoneTime.slice(11); // Extract the first 8 characters (HH:mm:ss)

      const weekOfWithTime = periodDate + "T" + timezoneTimeHours;
      return formatWithLocale(new Date(weekOfWithTime), "d/LLL");
    },
    [formatWithLocale]
  );

  const dailyInboxUsageSummaryFormatted =
    dailyInboxUsageSummary.period &&
    dailyInboxUsageSummary.period.conversations_summary_in_period?.map(
      (data) => {
        return {
          [t("reports:day", "Day")]: formatDateInCurrentTimezone(
            data.period_date
          ),
          [t("reports:received-chats", "Received chats")]: data.received_chats,
          [t("reports:distinct-contacts", "Distinct contacts")]:
            data.distinct_contacts,
          [t("reports:average-duration", "Avg duration")]:
            data.average_duration_sec,
          [t("reports:average-first-response", "Avg first response")]:
            data.average_first_response_sec,
          [t("reports:average-rating", "Avg rating")]:
            data.average_satisfaction_rating,
        };
      }
    );

  let receivedChatsTotal = 0;
  let receivedChats = "0";
  let distinctContacts = "0";
  let averageDuration = "00:00";
  let averageFirstResponse = "00:00";
  let averageRating = "0";
  if (dailyInboxUsageSummaryFormatted) {
    receivedChatsTotal = sumArray(
      dailyInboxUsageSummaryFormatted,
      t("reports:received-chats", "Received chats")
    );
    distinctContacts = numberFormatter(
      sumArray(
        dailyInboxUsageSummaryFormatted,
        t("reports:distinct-contacts", "Distinct contacts")
      )
    );
    averageDuration = durationFormatter(
      sumArray(
        dailyInboxUsageSummaryFormatted,
        t("reports:average-duration", "Avg duration")
      ) / dailyInboxUsageSummaryFormatted.length
    );
    averageFirstResponse = durationFormatter(
      sumArray(
        dailyInboxUsageSummaryFormatted,
        t("reports:average-first-response", "Avg first response")
      ) / dailyInboxUsageSummaryFormatted.length
    );
    averageRating = numberFormatter(
      sumArray(
        dailyInboxUsageSummaryFormatted,
        t("reports:average-rating", "Avg rating")
      ) / dailyInboxUsageSummaryFormatted.length
    );
    receivedChats = numberFormatter(receivedChatsTotal);
  }

  return (
    <Card className="p-0">
      {!dailyInboxUsageSummaryFormatted ? (
        <Loading />
      ) : (
        <TabGroup>
          <TabList color="blue" className="overflow-y-auto">
            <Tab className="px-4 py-3 sm:px-6 sm:py-4 text-left">
              <Text>
                <Trans i18nKey="reports:chart.received-chats">
                  All <br />
                  Conversations
                </Trans>
              </Text>
              <Metric className="mt-1 text-inherit">{receivedChats}</Metric>
            </Tab>
            <Tab className="px-4 py-3 sm:px-6 sm:py-4 text-left">
              <Text>
                <Trans i18nKey="reports:chart.distinct-contacts">
                  Distinct <br />
                  Contacts
                </Trans>
              </Text>
              <Metric className="mt-1 text-inherit">{distinctContacts}</Metric>
            </Tab>
            <Tab className="px-4 py-3 sm:px-6 sm:py-4 text-left">
              <Text>
                <Trans i18nKey="reports:chart.avg-duration">
                  Avg <br />
                  Duration <br />
                  (by day)
                </Trans>
              </Text>
              <Metric className="mt-1 text-inherit">{averageDuration}</Metric>
            </Tab>
            <Tab className="px-4 py-3 sm:px-6 sm:py-4 text-left">
              <Text>
                <Trans i18nKey="reports:chart.avg-response-time">
                  Avg time <br />
                  first response <br />
                  (by day)
                </Trans>
              </Text>
              <Metric className="mt-1 text-inherit">
                {averageFirstResponse}
              </Metric>
            </Tab>
            <Tab className="px-4 py-3 sm:px-6 sm:py-4 text-left">
              <Text>
                <Trans i18nKey="reports:chart.avg-rating">
                  Avg <br />
                  rating <br />
                  (by day)
                </Trans>
              </Text>
              <Metric className="mt-1 text-inherit">{averageRating}</Metric>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel className="p-6">
              <LineChart
                className="h-80 mt-5"
                data={dailyInboxUsageSummaryFormatted}
                index={t("reports:day", "Day")}
                categories={[t("reports:received-chats", "Received chats")]}
                colors={["blue"]}
                valueFormatter={numberFormatter}
                showYAxis={true}
                showLegend={true}
                yAxisWidth={50}
              />
            </TabPanel>
            <TabPanel className="p-6">
              <LineChart
                className="h-80 mt-5"
                data={dailyInboxUsageSummaryFormatted}
                index={t("reports:day", "Day")}
                categories={[
                  t("reports:distinct-contacts", "Distinct contacts"),
                ]}
                colors={["blue"]}
                valueFormatter={numberFormatter}
                showYAxis={true}
                showLegend={true}
                yAxisWidth={50}
              />
            </TabPanel>
            <TabPanel className="p-6">
              <LineChart
                className="h-80 mt-5"
                data={dailyInboxUsageSummaryFormatted}
                index={t("reports:day", "Day")}
                categories={[t("reports:average-duration", "Avg duration")]}
                colors={["blue"]}
                valueFormatter={durationFormatter}
                showYAxis={true}
                showLegend={true}
                yAxisWidth={50}
              />
            </TabPanel>
            <TabPanel className="p-6">
              <LineChart
                className="h-80 mt-5"
                data={dailyInboxUsageSummaryFormatted}
                index={t("reports:day", "Day")}
                categories={[
                  t("reports:average-first-response", "Avg first response"),
                ]}
                colors={["blue"]}
                valueFormatter={durationFormatter}
                showYAxis={true}
                showLegend={true}
                yAxisWidth={50}
              />
            </TabPanel>
            <TabPanel className="p-6">
              <LineChart
                className="h-80 mt-5"
                data={dailyInboxUsageSummaryFormatted}
                index={t("reports:day", "Day")}
                categories={[t("reports:average-rating", "Avg rating")]}
                colors={["blue"]}
                valueFormatter={numberFormatter}
                showYAxis={true}
                showLegend={true}
                yAxisWidth={50}
              />
            </TabPanel>
          </TabPanels>
        </TabGroup>
      )}
    </Card>
  );
}
