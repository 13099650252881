import { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { RadioGroup } from "@headlessui/react";
import { ArrowSmRightIcon } from "@heroicons/react/outline";
import { classNames } from "src/Helpers";
import TextAreaField from "../../components/Form/TextAreaField";
import TextInputField from "../../components/Form/TextInputField";
import StateButton from "../../components/StateButton";
import APIErrors from "../../components/base/APIErrors";
import * as meta from "../account/AccountMeta";

export interface RadioButtonGroupProps {
  options: {
    label: string;
    value: string;
  }[];
  name: string;
  label: string;
}

export default function OnboardingFormBasicInfo({
  formik,
  backendError,
  backendValidationErrors,
  success,
  submitted,
}) {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (name, value) => {
      formik.setFieldValue(name, value);
    },
    [formik]
  );

  const RadioButtonGroup = ({
    options,
    name,
    label,
  }: RadioButtonGroupProps) => {
    return (
      <div className={formik.errors[name] && "bg-red-50 p-2 rounded-md"}>
        <div className="text-normal font-medium text-center mb-4 text-gray-700">
          {label}
        </div>
        <RadioGroup
          value={formik.values[name]}
          onChange={(val) => handleChange(name, val)}
        >
          <div className="flex gap-2 flex-wrap justify-center rounded-md">
            {options.map((obj) => (
              <RadioGroup.Option
                key={obj.value}
                value={obj.value}
                className={({ checked }) =>
                  classNames(
                    checked
                      ? "z-10 border-indigo-200 bg-indigo-50"
                      : "border-gray-200",
                    "rounded-md relative flex bg-white cursor-pointer border py-2 px-4 focus:outline-none"
                  )
                }
              >
                {({ active, checked }) => (
                  <div className="flex items-center">
                    <span
                      className={classNames(
                        checked
                          ? "bg-indigo-600 border-transparent"
                          : "bg-white border-gray-300",
                        active ? "ring-2 ring-offset-2 ring-indigo-600" : "",
                        "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                      )}
                      aria-hidden="true"
                    >
                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                    </span>
                    <span className="ml-3">
                      <RadioGroup.Label
                        as="span"
                        className={classNames(
                          checked ? "text-indigo-900" : "text-gray-900",
                          "text-sm"
                        )}
                      >
                        {t(obj.label)}
                      </RadioGroup.Label>
                    </span>
                  </div>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
        <div>
          {formik.errors[name] && (
            <p className="mt-2 ml-2 text-sm text-red-500 ">
              {formik.errors[name] as string}
            </p>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="flex min-h-full justify-center">
      <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full sm:w-[300px] md:w-[500px] ">
          <div className="row text-center mb-10">
            <h1 className="mt-5 mb-1 text-2xl font-bold leading-7 text-gray-900">
              <Trans i18nKey="login:onboarding.title">
                🎤 Tell us about yourself so we can{" "}
                <span className="bg-gradient-to-r from-hilos to-red-400 bg-clip-text text-transparent">
                  set you up
                </span>
                !
              </Trans>
            </h1>
          </div>

          <div className="my-8">
            <APIErrors
              APIError={backendError}
              APIValidationErrors={backendValidationErrors}
              fieldTranslations={meta.FIELDS}
            ></APIErrors>
          </div>

          <div className="my-8 space-y-4">
            <TextInputField
              placeholder={t(meta.FIELDS.name.placeholder)}
              label={t(meta.FIELDS.name.label)}
              name={meta.FIELDS.name.key}
              type="text"
            />
          </div>
          <hr />
          <div className="my-8 space-y-12">
            <RadioButtonGroup
              options={meta.COMPANY_SIZES}
              name={meta.FIELDS.onboarding_company_size.key}
              label={t(meta.FIELDS.onboarding_company_size.label)}
            />
            <RadioButtonGroup
              options={meta.COMPANY_ROLES}
              name={meta.FIELDS.onboarding_company_role.key}
              label={`${t(meta.FIELDS.onboarding_company_role.label)} ${
                formik.values[meta.FIELDS.name.key] ||
                t("settings:company-role.no-company-name", "your company")
              }?`}
            />
            <div className="space-y-4">
              <RadioButtonGroup
                options={meta.USER_SOURCES}
                name={meta.FIELDS.onboarding_user_source.key}
                label={t(meta.FIELDS.onboarding_user_source.label)}
              />
              {formik.values &&
                formik.values[meta.FIELDS.onboarding_user_source.key] ===
                  "OTHER" && (
                  <>
                    <hr />

                    <TextAreaField
                      placeholder={t(
                        meta.FIELDS.onboarding_user_source_other.placeholder
                      )}
                      label={t(meta.FIELDS.onboarding_user_source_other.label)}
                      name={meta.FIELDS.onboarding_user_source_other.key}
                      rows={3}
                    />
                  </>
                )}
            </div>
          </div>
          <div className="my-6">
            <div className="flex justify-end">
              <StateButton
                isSubmitting={formik.isSubmitting}
                submitted={submitted}
                success={success}
                submittingText="Creating team..."
                successText={`Team ${
                  formik.values && formik.values.name
                } created!`}
                initialText={
                  <>
                    {t("start")}
                    <ArrowSmRightIcon
                      className="ml-1 h-5 w-5"
                      aria-hidden="true"
                    />
                  </>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
