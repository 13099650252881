import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { useLocation } from "react-router-dom";
import { NavigateFunction, matchPath, useNavigate } from "react-router-dom";
import { captureException } from "@sentry/react";
import {
  QueryClient as QueryClientNew,
  QueryClientProvider as QueryClientProviderNew,
} from "@tanstack/react-query";
import axios from "axios";
import { debounce } from "lodash";
import { usePostHog } from "posthog-js/react";
import { toast } from "sonner";
import usePostHogHelpers from "src/hooks/usePostHogHelpers";
import Loading from "./components/Loading";
import { getInboxParams } from "./helpers/inbox";
import {
  PLATFORM,
  deleteCurrentNotificationDevice,
  hasNotificationDevice,
  registerNotificationDevice,
  registerPushyNotification,
} from "./helpers/notifications";
import {
  getItemFromStorage,
  getUser,
  setCookieString,
} from "./helpers/session";
import useHilosStore from "./hooks/useHilosStore";
import useUserNotificationSync from "./hooks/useUserNotificationSync";
import { BASE_API_URL, PUBLIC_ROUTES } from "./router/router";
import { ServiceWorkerConfig } from "./serviceWorkerRegistration";

export const queryClient = new QueryClient();

export const queryClientNew = new QueryClientNew();

interface HilosProviderProps {
  config: ServiceWorkerConfig;
}

const IGNORE_404_ERROR_MODULES = ["inbox"];

function HilosProvider({
  children,
  config,
}: PropsWithChildren<HilosProviderProps>) {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { identifyUser, resetPostHog } = usePostHogHelpers();
  const [loading, setLoading] = useState(true);
  const navigateRef = useRef<NavigateFunction | null>(null);
  const lastAPIVersionRef = useRef<string | null>(null);
  const hasNetworkErrorRef = useRef<boolean>(false);
  const {
    session,
    resetSession,
    version,
    hasNetworkError,
    setHasNetworkError,
    setSession,
    setPendingUpdate,
    setVersion,
    setInboxParams,
    setIsNetworkOnline,
    setUnreadNotificationCount,
  } = useHilosStore();

  const posthog = usePostHog();
  const location = useLocation();

  useUserNotificationSync({
    connect: Boolean(!loading && session && session.id),
    setUnreadNotificationCount,
  });

  useEffect(() => {
    if (!posthog || process.env.NODE_ENV !== "production") {
      return;
    }

    try {
      if (
        session?.account &&
        session?.account.feature_flags.includes("force_recording") &&
        !posthog.sessionRecordingStarted
      ) {
        posthog?.startSessionRecording();
      }
    } catch (error) {
      console.error(error);
    }
  }, [posthog, session]);

  useEffect(() => {
    // Sending a event when ever they navigate between pages
    try {
      posthog?.capture("$pageview");
    } catch (error) {
      console.error(error);
    }
  }, [location, posthog]);

  const handleMessageEvent = useCallback(
    ({ data }) => {
      try {
        const { type, pathname } = data;

        if (type === "navigate_to") {
          navigate(pathname);
        }
      } catch (error) {
        captureException(error);
      }
    },
    [navigate]
  );

  const handleIsNetworkOnline = useCallback(() => {
    setIsNetworkOnline(true);
  }, [setIsNetworkOnline]);

  const handleIsNetworkOffline = useCallback(() => {
    setIsNetworkOnline(false);
  }, [setIsNetworkOnline]);

  useLayoutEffect(() => {
    axios.defaults.baseURL = BASE_API_URL;
    axios.defaults.xsrfCookieName = "csrftoken";
    axios.defaults.xsrfHeaderName = "X-CSRFToken";
    axios.defaults.withCredentials = true;

    // To identify requests from our own app
    axios.defaults.headers.common["X-Origin"] = "APP";

    axios.interceptors.response.use(
      (response) => {
        if (response.headers && response.headers["api-version"]) {
          if (lastAPIVersionRef.current !== response.headers["api-version"]) {
            if (lastAPIVersionRef.current) {
              setPendingUpdate();
              toast(
                t(
                  "new-version-available.title",
                  "There's a new version available!"
                ),
                {
                  description: t(
                    "new-version-available.description",
                    "To make sure everything works as expected, please reload Hilos. Don't forget to save your work first!"
                  ),
                  action: {
                    label: t("new-version-available.refresh", "Refresh"),
                    onClick: () => {
                      // @ts-ignore
                      window.location.reload(true);
                    },
                  },
                  duration: 60000,
                  important: true,
                }
              );
            } else {
              setVersion(response.headers["api-version"]);
            }

            lastAPIVersionRef.current = response.headers["api-version"];
          }

          if (hasNetworkErrorRef.current) {
            setHasNetworkError(false);
          }
        }
        return response;
      },
      (error) => {
        if (!error.response) {
          //Network error
          error.response = {
            status: 500,
            data: {
              non_field_errors: [
                "Ocurrió un error inesperado, intenta de nuevo.",
              ],
            },
          };
        }
        if (error.toJSON && error.toJSON().message === "Network Error") {
          if (!hasNetworkErrorRef.current) {
            setHasNetworkError(true);
          }
        }

        if (error.response && error.response.status === 401) {
          resetSession();
          const { pathname } = window.location;
          const isPublicRoute = PUBLIC_ROUTES.find((element) =>
            matchPath(element, pathname)
          );
          if (!isPublicRoute && navigateRef.current) {
            resetPostHog();
            let loginPath = "/login";
            if (pathname !== "/") {
              loginPath += `?next=${pathname}`;
            }
            navigateRef.current(loginPath);
          }
        }

        if (
          error.response?.status === 404 &&
          !IGNORE_404_ERROR_MODULES.includes(
            error.config?.headers?.["x-module"]
          )
        ) {
          const module = error.config?.headers?.["x-module"];

          if (module) {
            navigateRef.current?.(`/404?module=${module}`);
          }
        }

        return Promise.reject(error);
      }
    );
  }, [
    config,
    t,
    setVersion,
    setPendingUpdate,
    setHasNetworkError,
    resetPostHog,
    resetSession,
  ]);

  useLayoutEffect(() => {
    if (session && session.token_access) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${session.token_access}`;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }
  }, [session]);

  useEffect(() => {
    if ("navigator" in window && window.navigator.onLine) {
      handleIsNetworkOnline();
    }

    window.addEventListener("online", handleIsNetworkOnline);
    window.addEventListener("offline", handleIsNetworkOffline);

    return () => {
      window.removeEventListener("online", handleIsNetworkOnline);
      window.removeEventListener("offline", handleIsNetworkOffline);
    };
  }, [handleIsNetworkOnline, handleIsNetworkOffline]);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", handleMessageEvent);

      return () =>
        navigator.serviceWorker.removeEventListener(
          "message",
          handleMessageEvent
        );
    }
  }, [handleMessageEvent]);

  useEffect(() => {
    navigateRef.current = navigate;
  }, [navigate]);

  useEffect(() => {
    hasNetworkErrorRef.current = hasNetworkError;
  }, [hasNetworkError]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLoadSession = useCallback(
    debounce(async () => {
      try {
        let isAuthenticated = false;
        const localSession = getItemFromStorage("session");

        if (localSession) {
          const token = localSession.token_access;
          const user = await getUser(token);

          if (user) {
            const nextInboxParams = await getInboxParams(token);
            const nextSession = { ...localSession, ...user };

            localStorage.setItem("session", JSON.stringify(nextSession));

            switch (PLATFORM) {
              case "ANDROID":
                // @ts-ignore
                window.UtilityJSBridge.getPushyToken();
                break;
              case "IOS":
                // @ts-ignore
                window.webkit.messageHandlers.UtilityJSBridge.postMessage(
                  "getPushyToken"
                );
                break;
              case "BROWSER":
                const hasNotificationDeviceRegistered =
                  await hasNotificationDevice(token);

                if (!hasNotificationDeviceRegistered) {
                  await registerPushyNotification(token);
                }
                break;
              default:
                break;
            }

            setCookieString(token);
            setSession(nextSession);
            setInboxParams(nextInboxParams);
            identifyUser(user, "session load");
            isAuthenticated = true;
          }
        }

        if (!isAuthenticated) {
          const { pathname } = window.location;
          const isPublicRoute = PUBLIC_ROUTES.find((element) =>
            matchPath(element, pathname)
          );

          await deleteCurrentNotificationDevice();

          if (!isPublicRoute && navigateRef.current) {
            let loginPath = "/login";
            if (pathname !== "/") {
              loginPath += `?next=${pathname}`;
            }
            navigateRef.current(loginPath);
          }
        }
      } catch (error) {
        captureException(error);
      }
      setLoading(false);
    }, 250),
    [setSession]
  );

  const handlePushyTokenResult = useCallback((deviceToken: string) => {
    const localSession = getItemFromStorage("session");

    if (localSession) {
      const authToken = localSession.token_access;
      registerNotificationDevice(deviceToken, authToken);
      return true;
    }
    return false;
  }, []);

  useEffect(() => {
    handleLoadSession();
  }, [handleLoadSession]);

  useEffect(() => {
    window["onPushyTokenResult"] = handlePushyTokenResult;
    return () => {
      delete window["onPushyTokenResult"];
    };
  }, [handlePushyTokenResult]);

  useLayoutEffect(() => {
    if (lastAPIVersionRef.current && version !== lastAPIVersionRef.current) {
      // @ts-ignore
      window.location.reload(true);
    }
  }, [location, version, navigate]);

  if (loading) {
    return (
      <div className="h-full w-full flex justify-center items-center">
        <Loading />
      </div>
    );
  }

  return (
    <QueryClientProviderNew client={queryClientNew}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </QueryClientProviderNew>
  );
}

export default HilosProvider;
