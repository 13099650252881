import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { SquareCheckIcon, User, Waypoints, X } from "lucide-react";
import ConversationDetailsHeader from "src/containers/inbox/Conversation/ConversationDetailsHeader";
import Loading from "src/components/Loading";
import { Button } from "src/components/ui/button";
import useWorkflowTaskDetails from "src/hooks/useWorkflowTaskDetails";
import { classNames } from "src/Helpers";
import { buildRoute } from "src/router/router";
import ActionsTab from "../inbox/Conversation/ActionTab/ActionsTab";
import ContactTab from "../inbox/Conversation/ContactTab/ContactTab";
import TaskAssignedTo from "./TaskAssignedTo";

export default function WorkflowBoardTaskDetails() {
  const [t] = useTranslation();
  const { taskId, stateId, workflowId } = useParams() as {
    taskId: string;
    stateId: string;
    workflowId: string;
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState(
    searchParams.get("tab") || "task"
  );

  const { task, isLoading, handleUpdateWorkflowTask } = useWorkflowTaskDetails({
    taskId,
    workflowId,
  });

  const handleTabClick = (tabName) => {
    searchParams.set("tab", tabName);
    setCurrentTab(tabName);
    setSearchParams(searchParams);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (!task) {
    return null;
  }

  return (
    <div className="flex flex-col w-full h-full overflow-hidden pt-0 space-y-4 bg-white">
      <div className="absolute right-0 flex flex-1 flex-row items-center justify-end pt-2 pr-2">
        <Button variant="ghost" size="icon" className="p-1">
          <Link
            to={buildRoute("workflow-task", {
              workflowId,
              stateId,
              taskId,
            })}
          >
            <X className="h-4 w-4" />
          </Link>
        </Button>
      </div>
      <div className="flex flex-col h-full overflow-y-auto">
        <ConversationDetailsHeader
          inboxContact={task.inbox_contact}
          contact={task.inbox_contact.contact}
          silenced={task.inbox_contact.is_silenced}
          archived={task.inbox_contact.is_archived}
          lastInboundMessageOn={task.inbox_contact.last_inbound_message_on}
        />
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex" aria-label="Tabs">
            {[
              {
                name: "task",
                label: t("workflows:task.details.task", "Task"),
                icon: SquareCheckIcon,
              },
              {
                name: "contact",
                label: t("workflows:task.details.contact", "Contact"),
                icon: User,
              },
              {
                name: "actions",
                label: t("workflows:task.details.actions", "Actions"),
                icon: Waypoints,
              },
            ].map((tab) => (
              <button
                key={tab.name}
                data-tour={`tab-${tab.name.toLowerCase()}-conversation-details`}
                type="button"
                onClick={() => handleTabClick(tab.name)}
                className={classNames(
                  tab.name === currentTab
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "flex grow items-center justify-center border-b-2 py-2 px-2 text-center text-sm font-medium gap-1"
                )}
                aria-current={tab.name === currentTab ? "page" : undefined}
              >
                <tab.icon />
                <span>{tab.label}</span>
              </button>
            ))}
          </nav>
        </div>

        <div className="flex flex-1 space-y-5" data-tour="convo-detail-tab">
          {currentTab === "task" && (
            <div className="flex flex-1 grow flex-col space-y-5 py-3 overflow-x-hidden">
              <TaskAssignedTo
                task={task}
                onUpdateWorkflowTask={handleUpdateWorkflowTask}
              />
            </div>
          )}
          {currentTab === "contact" && (
            <ContactTab contactId={task.inbox_contact.contact.id} />
          )}
          {currentTab === "actions" && (
            <ActionsTab inboxContact={task.inbox_contact} />
          )}
        </div>
      </div>
    </div>
  );
}
