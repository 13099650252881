import { useEffect, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import { axiosErr } from "@hilos/types/axios";
import usePostHogHelpers from "src/hooks/usePostHogHelpers";
import useHilosStore from "../../hooks/useHilosStore";
import { API_ROUTES, buildAPIRoute, buildRoute } from "../../router/router";
import { getInitialValues } from "../account/AccountMeta";
import OnboardingForm from "./OnboardingForm";

export default function OnboardingInBrowser({ setOnboardingType }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { session, reloadSession } = useHilosStore();
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { maybeStartRecording } = usePostHogHelpers();

  useEffect(() => {
    if (session && session.account) {
      navigate(buildRoute("dashboard"));
    }
  }, [session, navigate]);

  useEffect(() => {
    maybeStartRecording();
  }, []);

  if (!session) {
    return null;
  }

  const addTrackingData = (formData) => {
    const trackingFields = [
      "utm_source",
      "utm_term",
      "utm_campaign",
      "utm_medium",
      "referral",
    ];
    trackingFields.forEach(
      (field) =>
        (formData[field] =
          localStorage.getItem(field) && localStorage.getItem(field) !== "null"
            ? localStorage.getItem(field)
            : "")
    );
  };

  const formSubmit = async (
    values,
    setSubmitting,
    setBackendValidationErrors,
    setBackendError
  ) => {
    setBackendValidationErrors({});
    setBackendError("");
    addTrackingData(values);
    try {
      await axios.post(buildAPIRoute(API_ROUTES.ACCOUNT_CREATE_DETAIL), values);
      setSuccess(true);
      await reloadSession();
      navigate(buildRoute("connect-sandbox"));
    } catch (error) {
      const err = error as axiosErr;
      Sentry.captureException(err);
      setSuccess(false);
      if (err?.response?.status === 400) {
        console.log("error", err);
        setBackendValidationErrors(err.response.data);
      } else {
        setBackendError("An error occurred, please try again.");
      }
    } finally {
      setSubmitting(false);
      setSubmitted(true);
      setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
    }
  };

  return (
    <div className="border-top border-top-2 flex w-full h-full items-center justify-center border-hilos bg-gray-50">
      <div className="h-full self-center w-full">
        <div className="flex-col h-full w-full overflow-y-scroll no-scrollbar">
          <div className="w-2 mx-auto">
            <ConfettiExplosion
              {...{
                force: 0.6,
                duration: 4000,
                particleCount: 80,
                width: 1600,
              }}
            />
          </div>
          <OnboardingForm
            account={getInitialValues(session.email)}
            formSubmit={formSubmit}
            success={success}
            submitted={submitted}
          />
          <div className="text-center">
            <button
              className="text-sm text-gray-500 underline"
              type="button"
              onClick={() => setOnboardingType("WA")}
            >
              {t(
                "settings:onboarding.continue-in-wa",
                "Or continue in WhatsApp"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
