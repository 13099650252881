import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChatAltIcon, PlusSmIcon } from "@heroicons/react/outline";
import axios from "axios";
import { ChannelAvailabilityData } from "@hilos/types/channel";
import { InboxParams } from "@hilos/types/hilos";
import Loading from "src/components/Loading";
import { getQueryFilters } from "src/helpers/inbox";
import { GET_INBOX_CONTACT_IDS_QUERY } from "src/helpers/queries";
import useHilosStore from "src/hooks/useHilosStore";
import { API_ROUTES, BASE_GRAPHQL_HTTP } from "src/router/router";
import ConversationListAction from "./ConversationListAction";
import ConversationListData from "./ConversationListData";

interface ConversationListProps {
  data: any;
  search: string;
  updating: boolean;
  fetching: boolean;
  hasMoreData: boolean;
  orderByField: string;
  inboxParams: InboxParams;
  currentInboxContactId?: string;
  totalConversations: number;
  currentAvailableChannels: ChannelAvailabilityData[];
  onFetchMore: (cursor: string) => void;
  onInboxContactCursor: (id: string | null) => void;
  onShowNewConversation: () => void;
}

function ConversationList({
  data,
  search,
  updating,
  fetching,
  hasMoreData,
  orderByField,
  inboxParams,
  currentInboxContactId,
  totalConversations,
  currentAvailableChannels,
  onFetchMore,
  onInboxContactCursor,
  onShowNewConversation,
}: ConversationListProps) {
  const { t } = useTranslation();
  const { session } = useHilosStore();
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState<string[]>([]);

  const handleSelectAll = useCallback(() => {
    setIsSelectedAll(true);
    setSelectedContacts([]);
  }, []);

  const handleUnselectAll = useCallback(() => {
    setIsSelectedAll(false);
    setSelectedContacts([]);
  }, []);

  const handleSelectContact = useCallback((id: string) => {
    setSelectedContacts((prevSelectedContacts) => {
      const nextSelectedContacts = [...prevSelectedContacts];
      const selectedContactIndex = prevSelectedContacts.findIndex(
        (selectedContactId) => selectedContactId === id
      );
      if (selectedContactIndex !== -1) {
        nextSelectedContacts.splice(selectedContactIndex, 1);
      } else {
        nextSelectedContacts.push(id);
      }
      return nextSelectedContacts;
    });
  }, []);

  const handleInboxAction = useCallback(
    async (action) => {
      const params = { ...action };

      try {
        if (isSelectedAll) {
          const filters = getQueryFilters({
            session,
            inboxParams,
            currentAvailableChannels,
          });
          const variables = { filters };

          const { data: selectedContactsByFilters } = await axios.post(
            BASE_GRAPHQL_HTTP as string,
            {
              query: GET_INBOX_CONTACT_IDS_QUERY,
              variables,
            },
            {
              headers: {
                "x-hasura-role": "api-user",
              },
              withCredentials: true,
            }
          );

          params["selected_contacts"] =
            selectedContactsByFilters?.data?.api_inboxcontact?.map(
              (inboxContact) => inboxContact.id
            ) || [];
        } else {
          params["selected_contacts"] = selectedContacts;
        }
      } catch {}

      await axios.post(API_ROUTES.INBOX_CONTACT_LIST_ACTION, params);
    },
    [
      isSelectedAll,
      session,
      inboxParams,
      selectedContacts,
      currentAvailableChannels,
    ]
  );

  useEffect(() => {
    if (!totalConversations) {
      setIsSelectedAll(false);
    }
  }, [totalConversations]);

  useEffect(() => {
    setSelectedContacts([]);
  }, [inboxParams]);

  if (updating) {
    return (
      <div className="flex-1 overflow-y-auto bg-white shadow-sm">
        <Loading />
      </div>
    );
  }

  if (search && search.length < 3) {
    return (
      <div className="flex-1 py-5 text-center">
        <ChatAltIcon className="mx-auto h-12 w-12 text-gray-400" />
        <h3 className="mt-2 text-sm font-medium text-gray-900">
          {t(
            "inbox:conversations.min-chars-to-search",
            "At least 3 characters are required to get search results."
          )}
        </h3>
      </div>
    );
  }

  if (data?.api_inboxcontact?.length > 0) {
    return (
      <>
        <ConversationListAction
          isSelectedAll={isSelectedAll}
          totalConversations={totalConversations}
          totalSelectedContacts={selectedContacts.length}
          onSelectAll={handleSelectAll}
          onUnselectAll={handleUnselectAll}
          onInboxAction={handleInboxAction}
        />
        <ConversationListData
          data={data.api_inboxcontact}
          fetching={fetching}
          hasMoreData={hasMoreData}
          isSelectedAll={isSelectedAll}
          selectedContacts={selectedContacts}
          currentInboxContactId={currentInboxContactId}
          orderByField={orderByField}
          onFetchMore={onFetchMore}
          onSelectContact={handleSelectContact}
          onInboxContactCursor={onInboxContactCursor}
        />
      </>
    );
  }

  return (
    <div className="flex-1 py-5 text-center">
      <ChatAltIcon className="mx-auto h-12 w-12 text-gray-400" />
      <h3 className="mt-2 text-sm font-medium text-gray-900">
        {t(
          "inbox:conversations.no-conversations-found",
          "No conversations with messages found."
        )}
      </h3>
      <div className="mt-3">
        <button
          type="button"
          className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          onClick={onShowNewConversation}
        >
          <PlusSmIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true" />
          {t(
            "inbox:conversations.start-conversation",
            "Start a new conversation"
          )}
        </button>
      </div>
    </div>
  );
}

export default ConversationList;
