import { DropzoneInputProps } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { QueryObserverResult } from "react-query";
import { Link } from "react-router-dom";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { UpdateInboxContactFn } from "@hilos/hooks/useInboxContactDetails";
import {
  ConversationContent,
  InboxContactRead,
} from "@hilos/types/private-schema";
import { WhatsAppTemplateProvider } from "src/hooks/WhatsAppTemplateProvider";
import { SendMessageFn } from "src/hooks/useConversationContent";
import useHilosStore from "src/hooks/useHilosStore";
import { buildRoute } from "src/router/router";
import BottomBarMessageForm from "./BottomBarMessageForm";
import BottomBarTemplateSend from "./BottomBarTemplateSend";

interface ConversationFooterProps {
  inboxContact: InboxContactRead;
  context: ConversationContent | null;
  mustSendTemplate: boolean;
  getInputProps: <T extends DropzoneInputProps>(props?: T) => T;
  onSendMessage: SendMessageFn;
  onUpdateInboxContact: UpdateInboxContactFn;
  onScheduleMessage: () => Promise<QueryObserverResult<any, unknown>>;
  onSelectContextContent: (content: ConversationContent | null) => void;
  onFocusConversationContent: (data: ConversationContent) => void;
  onDisableMessageMediaUpload: (disable: boolean) => void;
}

function ConversationFooter({
  inboxContact,
  context,
  mustSendTemplate,
  getInputProps,
  onSendMessage,
  onUpdateInboxContact,
  onScheduleMessage,
  onSelectContextContent,
  onFocusConversationContent,
  onDisableMessageMediaUpload,
}: ConversationFooterProps) {
  const { session } = useHilosStore();
  const { t } = useTranslation();

  if (
    session?.account.has_used_multiple_channels &&
    inboxContact.channel.status !== "ACTIVE"
  ) {
    return (
      <div className="rounded-md bg-yellow-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationCircleIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">
              {t("inbox:channel-inactive", "This channel is inactive")}
            </h3>
            <div className="mt-2 text-sm text-yellow-700">
              <p>
                {t(
                  "inbox:channel-inactive-description",
                  "You can see the entire conversation history but won't be able to send messages using this number."
                )}
              </p>
            </div>
            <div className="mt-4">
              <div className="-mx-2 -my-1.5 flex">
                <Link
                  to={buildRoute("config-channel-cloud-api-details", {
                    id: inboxContact.channel.id,
                  })}
                  className="rounded-md bg-yellow-50 px-2 py-1.5 text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50"
                >
                  {t(
                    "inbox:channel-inactive-see-details",
                    "See channel details"
                  )}
                  <span aria-hidden="true"> &rarr;</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex-shrink-0 border-t border-gray-200 bg-gray-100 pt-2">
      <div className="min-w-0" data-tour="convo-footer">
        <WhatsAppTemplateProvider>
          {mustSendTemplate ? (
            <BottomBarTemplateSend inboxContact={inboxContact} />
          ) : (
            <BottomBarMessageForm
              inboxContact={inboxContact}
              context={context}
              getInputProps={getInputProps}
              onSendMessage={onSendMessage}
              onScheduleMessage={onScheduleMessage}
              onUpdateInboxContact={onUpdateInboxContact}
              onSelectContextContent={onSelectContextContent}
              onFocusConversationContent={onFocusConversationContent}
              onDisableMessageMediaUpload={onDisableMessageMediaUpload}
            />
          )}
        </WhatsAppTemplateProvider>
      </div>
    </div>
  );
}

export default ConversationFooter;
